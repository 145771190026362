import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { CivixApiService } from '../civix-api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private _civixApiService: CivixApiService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {

    if (localStorage.login && !this.authService.isLoggedIn) {
      sessionStorage.setItem('login', localStorage.getItem('login'));
      sessionStorage.setItem('nombre', localStorage.getItem('nombre'));
      sessionStorage.setItem('imagen', localStorage.getItem('imagen'));
      return this.verifyTokenValid(url);
    } else {
      return new Observable<boolean>((observer) => {
        if (this.authService.isLoggedIn) {
          console.log('already logged in');
          return observer.next(true);
        }
        console.log('going to login');
        this.authService.redirectUrl = url;
        this.router.navigate(['/login']);
        return observer.next(false);
      })
    }
  }

  verifyTokenValid(url: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this._civixApiService.getArbolModulos(sessionStorage.login).subscribe((data: any) => {
        console.log('success validating');
        this.authService.userVerified();
        observer.next(true);
      }, (error) => {
        console.log(error);
        console.log('going to login');
        this.authService.redirectUrl = url;
        this.router.navigate(['/login']);
        observer.next(false);
      });
    });
  }
}
