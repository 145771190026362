import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CivixApiService } from 'app/civix-api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CrmStateService } from '../crm-state.service';
import { CrmCatalogsService } from '../crm-catalogs.service';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {

  filterControls: FormGroup;
  temas: any[];
  temasFiltrados: any[];
  categorias: any[];
  categoriasFiltradas: any[];
  medios: any[];

  private _unsubscribeAll: Subject<any>;

  constructor(private _formBuilder: FormBuilder, private crmStateService: CrmStateService, private crmCatalogsService: CrmCatalogsService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.crmStateService.filterSettings
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((filterSettings: any) => {
        this.filterControls = this._formBuilder.group({
          palabraClave: filterSettings.palabraClave,
          tema: filterSettings.tema,
          temaFiltro: filterSettings.temaFiltro,
          categoria: filterSettings.categoria,
          categoriaFiltro: filterSettings.categoriaFiltro,
          estatus: filterSettings.estatus,
          medio: filterSettings.medio,
          fechaDesde: filterSettings.fechaDesde,
          fechaHasta: filterSettings.fechaHasta
        });
      });
    this.filterControls.get('tema').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterCategorias();
      });

    this.filterControls.get('temaFiltro').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterTemas();
      });

    this.filterControls.get('categoriaFiltro').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterCategorias();
      });

    this.crmCatalogsService.temas
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(temas => {
        this.temas = temas;
        this.temasFiltrados = temas;
      });
    this.crmCatalogsService.categorias
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(categorias => {
        this.categorias = categorias;
        this.categoriasFiltradas = categorias;
      });
    this.crmCatalogsService.medios
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(medios => {
        this.medios = medios;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  resetFilters() {
    this.filterControls.get('palabraClave').setValue('');
    this.filterControls.get('tema').setValue('');
    this.filterControls.get('temaFiltro').setValue('');
    this.filterControls.get('categoria').setValue('');
    this.filterControls.get('categoriaFiltro').setValue('');
    this.filterControls.get('estatus').setValue('');
    this.filterControls.get('medio').setValue('');
    this.filterControls.get('fechaDesde').setValue(null);
    this.filterControls.get('fechaHasta').setValue(null);
  }

  applyFilters() {
    this.crmStateService.setFilterSettings({
      palabraClave: this.filterControls.get('palabraClave').value,
      tema: this.filterControls.get('tema').value,
      temaFiltro: this.filterControls.get('temaFiltro').value,
      categoria: this.filterControls.get('categoria').value,
      categoriaFiltro: this.filterControls.get('categoriaFiltro').value,
      estatus: this.filterControls.get('estatus').value,
      medio: this.filterControls.get('medio').value,
      fechaDesde: this.filterControls.get('fechaDesde').value,
      fechaHasta: this.filterControls.get('fechaHasta').value
    });
  }

  private _filterTemas(): void {
    let search = this.filterControls.get('temaFiltro').value;
    if (!search) {
      this.temasFiltrados = this.temas;
      return;
    } else {
      search = search.toLowerCase();
    }
    let temasFiltrados = this.temas.filter(tema => tema.nombre.toLowerCase().includes(search));
    this.temasFiltrados = temasFiltrados;
  }

  private _filterCategorias(): void {
    let tema = this.filterControls.get('tema').value;
    let searchCategoria = this.filterControls.get('categoriaFiltro').value || '';
    if (tema) {
      let categoriasFiltradas = this.categorias.filter(categoria => {
        if (categoria.temaId === tema.id) {
          return categoria.nombre.toLowerCase().includes(searchCategoria.toLowerCase());
        } else {
          return false;
        }
      });
      this.categoriasFiltradas = categoriasFiltradas.sort(this.categorySortFunction);
    } else {
      let categoriasFiltradas = this.categorias.filter(categoria => {
        return categoria.nombre.toLowerCase().includes(searchCategoria.toLowerCase());
      });
      this.categoriasFiltradas = categoriasFiltradas.sort(this.categorySortFunction);
    }
  }

  private categorySortFunction(a, b) {
    let catA = a.nombre.toLowerCase();
    let catB = b.nombre.toLowerCase();
    if (catA > catB) {
      return 1;
    } else if (catA < catB) {
      return -1;
    } else {
      return 0;
    }
  }
}
