import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { CivixApiService } from 'app/civix-api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  filterControls: FormGroup;
  private _unsubscribeAll: Subject<any>;

  constructor(private _formBuilder: FormBuilder, private civixApiService: CivixApiService, private _snackBar: MatSnackBar) { 
    this._unsubscribeAll = new Subject();
    this.filterControls = this._formBuilder.group({
      fechaDesde: new Date((new Date().getTime() - (30 * 24 * 60 * 60 * 1000))),
      fechaHasta: new Date()
    });
  }

  ngOnInit() {

  }

  generateReport(){
    let parameters = {
      'token': sessionStorage.login,
      'fechaInicio': this.filterControls.get('fechaDesde').value,
      'fechaFin': this.filterControls.get('fechaHasta').value,
      'filtro': 'despacho',
      'keyword': '',
      'temaId': '',
      'categoriaId': '',
      'estatus': '',
      'timeZoneOffset': new Date().getTimezoneOffset()
    };
    let _this = this;
    _this.civixApiService.generarReporteGrupoSolucionCSV(parameters)
    .pipe(takeUntil(_this._unsubscribeAll))
    .subscribe((data: any) => {
      if (data.response && data.response.disponible) {
        _this._snackBar.open('El reporte será enviado a tu correo. Puede demorar hasta 5 minutos en ser generado.', 'Ok', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000
        });
      } else {
        _this._snackBar.open('Error al generar reporte.', 'Ok', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000
        });
      }
    });
  }

}
