import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ImageDialogComponent } from 'app/image-dialog/image-dialog.component';
import { CrmStateService } from '../crm-state.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Report } from '../report-list/report.model';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { AddCommentDialogComponent } from '../crm-dialogs/add-comment-dialog/add-comment-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FormControl, Validators } from '@angular/forms';
import { CloseReportDialogComponent } from '../crm-dialogs/close-report-dialog/close-report-dialog.component';
import { CivixApiService } from 'app/civix-api.service';
import { AddGroupDialogComponent } from 'app/crm/crm-dialogs/add-group-dialog/add-group-dialog.component';
import { RelocateReportDialogComponent } from '../crm-dialogs/relocate-report-dialog/relocate-report-dialog.component';
import { EditCategoryDialogComponent } from '../crm-dialogs/edit-category-dialog/edit-category-dialog.component';
import { CorreoRelevanteDialogComponent } from '../crm-dialogs/correo-relevante-dialog/correo-relevante-dialog.component';


@Component({
  selector: 'app-detail-report',
  templateUrl: './detail-report.component.html',
  styleUrls: ['./detail-report.component.scss']
})
export class DetailReportComponent implements OnInit {

  @Input()
  reportDetail: any;
  currentReport: Report;
  conversation: any[];
  reportStatus = new FormControl('');
  folioExterno = new FormControl('', Validators.required);
  descripcion = new FormControl('');
  editingExternalFolio = false;
  editingDescripcion = false;
  @ViewChild('externalFolioInput', { static: false })
  folioExternoIput;
  @ViewChild('descripcionInput', { static: false })
  descripcionInput;

  private _unsubscribeAll: Subject<any>;
  @ViewChild('notesScrollbar', { static: false })
  private _notesScrollbar: FusePerfectScrollbarDirective;
  @ViewChild('conversationScrollbar', { static: false })
  private _conversationScrollbar: FusePerfectScrollbarDirective;
  private noteImageCount: number = 0;
  private conversationImageCount: number = 0;

  constructor(private _crmStateService: CrmStateService, public dialog: MatDialog,
    private _fuseProgressBarService: FuseProgressBarService, private civixApiService: CivixApiService, private _snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    let _this = this;
    this._crmStateService.currentReport
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(currentReport => {
        this.currentReport = currentReport;
        if (currentReport && currentReport['_senderComponent'] !== 'detailReport_relocate') {
          this.resetReportDetail();
        }
      });

    this.reportStatus.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((change) => {
        if (change === 'cerrado') {
          this.openCloseReportDialog();
        } else if (this.reportDetail.geoPunto.categoriaId === '5eac612728cf1d00115df046' && change === 'en proceso') {
          this.openTipoDonacionDialog();
        } else if (change === 'abierto' || change === 'en proceso') {
          this.civixApiService.cambiarEstatusReporte({
            "token": sessionStorage.login,
            "geoPuntoId": this.reportDetail.geoPunto._id,
            "estatus": change
          }).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
              if (response.response === 'ok') {
                _this._snackBar.open('Estatus actualizado.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 2000
                });
                this.currentReport['_senderComponent'] = 'reportListComponent';
                this._crmStateService.setCurrentReport(this.currentReport);
              } else {
                _this._snackBar.open('Error al actualizar estatus.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 2000
                });
              }
            });
        }
      });
  }

  ngOnChanges() {
    if (this.reportDetail) {
      if (this.reportDetail.geoPunto) {
        this.reportStatus.setValue(this.reportDetail.geoPunto.estatus, { emitEvent: false });
        this.folioExterno.setValue(this.reportDetail.geoPunto.folioExterno, { emitEvent: false });
        this.descripcion.setValue(this.reportDetail.geoPunto.descripcion || '', { emitEvent: false });
      }
      this.getConversation(this.reportDetail);
      this.sortNotesAscendingOrder(this.reportDetail);
      this.countMessageImagesAndScroll();
    }
  }

  countMessageImagesAndScroll() {
    this.noteImageCount = 0;
    this.conversationImageCount = 0;
    if (this.reportDetail.notas) {
      for (let i = 0; i < this.reportDetail.notas.length; i++) {
        if (this.reportDetail.notas[i].imagenes) {
          this.noteImageCount += this.reportDetail.notas[i].imagenes.length;
        }
      }
    }
    if (this.conversation) {
      for (let i = 0; i < this.conversation.length; i++) {
        if (this.conversation[i].imagenes) {
          this.conversationImageCount += this.conversation[i].imagenes.length;
        }
      }
    }
    if (this.noteImageCount === 0) {
      setTimeout(() => {
        if (this._notesScrollbar) {
          this._notesScrollbar.scrollToBottom();
        }
      }, 0);
    }
    if (this.conversationImageCount === 0) {
      setTimeout(() => {
        if (this._conversationScrollbar) {
          this._conversationScrollbar.scrollToBottom();
        }
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  viewImage(imageUrl, i) {
    this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: imageUrl,
        index: i
      }
    })
  }

  resetReportDetail() {
    this.reportDetail = null;
    this.editingExternalFolio = false;
    this.editingDescripcion = false;
  }

  getConversation(reportDetail: any, comentarios?: any[]) {
    let conversation = [];
    if (reportDetail) {
      if (reportDetail.seguimientos) {
        if (reportDetail.comentarios) {
          conversation = reportDetail.seguimientos.concat(reportDetail.comentarios);
        } else {
          conversation = reportDetail.seguimientos;
        }
      } else {
        if (reportDetail.comentarios) {
          conversation = reportDetail.comentarios;
        } else {
          conversation = [];
        }
      }
    } else if (comentarios) {
      conversation = comentarios.filter((comment, i, array) => {
        if (comment.tipo === 'seguimiento' || comment.tipo === 'comentario') {
          return true;
        }
      });
    }
    conversation.sort((firstEl: any, secondEl: any) => {
      let firstDate = new Date(firstEl.createdAt);
      let secondDate = new Date(secondEl.createdAt);
      if (firstDate >= secondDate) {
        return 1;
      } else {
        return -1;
      }
    });
    this.conversation = conversation;
  }

  sortNotesAscendingOrder(reportDetail) {
    if (reportDetail.notas) {
      reportDetail.notas.sort((firstEl: any, secondEl: any) => {
        let firstDate = new Date(firstEl.createdAt);
        let secondDate = new Date(secondEl.createdAt);
        if (firstDate >= secondDate) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }

  noteImageLoaded() {
    this.noteImageCount--;
    if (this.noteImageCount === 0) {
      this._notesScrollbar.scrollToBottom();
    }
  }

  conversationImageLoaded() {
    this.conversationImageCount--;
    if (this.conversationImageCount === 0) {
      this._conversationScrollbar.scrollToBottom();
    }
  }

  openCommentDialog(type) {
    let addCommentDialogRef = this.dialog.open(AddCommentDialogComponent, {
      data: {
        type: type,
        geoPuntoId: this.reportDetail.geoPunto._id
      }
    });
    addCommentDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any[]) => {
        this._fuseProgressBarService.hide();
        if (result && result.length > 0) {
          if (type === 'Nota Interna') {
            let notas = result.filter((element, i, array) => {
              if (element.tipo === 'nota') {
                return true;
              }
            });
            this.reportDetail.notas = notas;
            this.sortNotesAscendingOrder(this.reportDetail);
            this.countMessageImagesAndScroll();
          } else if (type === 'Seguimiento') {
            this.getConversation(null, result);
            this.countMessageImagesAndScroll();
          }
        }
      });
  }

  openCloseReportDialog() {
    let closeReportDialogRef = this.dialog.open(CloseReportDialogComponent, {
      data: {
        geoPuntoId: this.reportDetail.geoPunto._id
      }
    });
    closeReportDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: boolean) => {
        if (result) {
          this.currentReport['_senderComponent'] = 'reportListComponent';
          this._crmStateService.setCurrentReport(this.currentReport);
        } else {
          this.reportStatus.setValue(this.reportDetail.geoPunto.estatus, { emitEvent: false });
        }
      });
  }

  openTipoDonacionDialog(){
    let closeReportDialogRef = this.dialog.open(CloseReportDialogComponent, {
      data: {
        geoPuntoId: this.reportDetail.geoPunto._id,
        seleccionarTipoDonacion: true
      }
    });
    closeReportDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: boolean) => {
        if (result) {
          this.currentReport['_senderComponent'] = 'reportListComponent';
          this._crmStateService.setCurrentReport(this.currentReport);
        } else {
          this.reportStatus.setValue(this.reportDetail.geoPunto.estatus, { emitEvent: false });
        }
      });
  }

  openAddGroupDialog() {
    let addGroupDialogRef = this.dialog.open(AddGroupDialogComponent, {
      data: {
        geoPuntoId: this.reportDetail.geoPunto._id
      }
    });
    addGroupDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: boolean) => {
        if (result) {
          this.currentReport['_senderComponent'] = 'reportListComponent';
          this._crmStateService.setCurrentReport(this.currentReport);
        }
      });
  }

  openRelocateReportDialog() {
    let _this = this;
    let relocateReportDialogRef = this.dialog.open(RelocateReportDialogComponent, {
      data: {
        geoPuntoId: this.reportDetail.geoPunto._id,
        currentAddress: this.reportDetail.geoPunto.direccionTextual,
        currentCoordinates: {
          longitud: this.currentReport.geo.coordinates[0],
          latitud: this.currentReport.geo.coordinates[1]
        }
      },
      width: '80%'
    });
    relocateReportDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result) {
          _this.currentReport['_senderComponent'] = 'detailReport_relocate';
          _this.reportDetail.geoPunto.direccionTextual = result.formattedAddress;
          _this.currentReport.geo.coordinates[0] = result.coordinates.longitud;
          _this.currentReport.geo.coordinates[1] = result.coordinates.latitud;
          _this._crmStateService.setCurrentReport(_this.currentReport);
        }
      });
  }

  enableEditFolioExterno() {
    let _this = this;
    this.editingExternalFolio = true;
    setTimeout(() => {
      if (_this.folioExternoIput) {
        _this.folioExternoIput.nativeElement.focus();
      }
    }, 0);
  }

  cancelEditingFolioExterno() {
    this.editingExternalFolio = false;
    if (this.reportDetail && this.reportDetail.geoPunto && this.reportDetail.geoPunto.folioExterno) {
      this.folioExterno.setValue(this.reportDetail.geoPunto.folioExterno, { emitEvent: false });
    }
  }

  editFolioExterno() {
    let _this = this;
    if (this.folioExterno.value && this.reportDetail && this.reportDetail.geoPunto) {
      let data = {
        'token': sessionStorage.login,
        'geoPuntoId': this.reportDetail.geoPunto._id,
        'folioExterno': this.folioExterno.value
      };
      this.civixApiService.editarFolioExterno(data)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data: any) => {
          if (data && data.response === 'ok') {
            _this._snackBar.open('Folio externo actualizado.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
            _this.currentReport['_senderComponent'] = 'reportListComponent';
            _this._crmStateService.setCurrentReport(_this.currentReport);
          } else {
            _this._snackBar.open('Error al actualizar folio externo.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          }
          _this.editingExternalFolio = false;
        });
    }
  }

  enableEditDescripcion() {
    let _this = this;
    this.editingDescripcion = true;
    setTimeout(() => {
      if (_this.descripcionInput) {
        _this.descripcionInput.nativeElement.focus();
      }
    }, 0);
  }

  cancelEditingDescripcion() {
    this.editingDescripcion = false;
    if (this.reportDetail && this.reportDetail.geoPunto && this.reportDetail.geoPunto.descripcion) {
      this.descripcion.setValue(this.reportDetail.geoPunto.descripcion, { emitEvent: false });
    }
  }

  editDescripcion() {
    let _this = this;
    if ((this.descripcion.value || this.descripcion.value === '') && this.reportDetail && this.reportDetail.geoPunto) {
      let data = {
        'token': sessionStorage.login,
        'geoPuntoId': this.reportDetail.geoPunto._id,
        'descripcion': this.descripcion.value
      };
      this.civixApiService.editarDescripcion(data)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((data: any) => {
          if (data && data.status === 'ok') {
            _this._snackBar.open('Descripción actualizada.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
            _this.currentReport['_senderComponent'] = 'reportListComponent';
            _this._crmStateService.setCurrentReport(_this.currentReport);
          } else {
            _this._snackBar.open('Error al actualizar descripción.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          }
          _this.editingDescripcion = false;
        });
    }
  }

  openEditCategoryDialog() {
    let editCategoryDialogRef = this.dialog.open(EditCategoryDialogComponent, {
      data: {
        folioCic: this.reportDetail.geoPunto.folioCic,
        categoriaId: this.reportDetail.geoPunto.categoriaId,
        tags: this.reportDetail.geoPunto.tagIdsOut || [],
        location: {
          longitud: this.currentReport.geo.coordinates[0],
          latitud: this.currentReport.geo.coordinates[1]
        }
      }
    });
    editCategoryDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: boolean) => {
        if (result) {
          this.currentReport['_senderComponent'] = 'reportListComponent';
          this._crmStateService.setCurrentReport(this.currentReport);
        }
      });
  }

  openEnviarCorreosDialog() {
    let correoRelevanteDialogRef = this.dialog.open(CorreoRelevanteDialogComponent, {
      data: {
        reporte: {
          _id: this.reportDetail.geoPunto._id,
          categoriaNombre: this.reportDetail.geoPunto.categoriaNombre,
          descripcion: this.reportDetail.geoPunto.descripcion,
          fechaEvento: this.reportDetail.geoPunto.fechaEvento
        }
      }
    });
    correoRelevanteDialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: boolean) => {
        if (result) {
          this.currentReport['_senderComponent'] = 'reportListComponent';
          this._crmStateService.setCurrentReport(this.currentReport);
        }
      });
  }
}
