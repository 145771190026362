import { Component, OnInit } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { AuthService } from '../auth/auth.service';

declare const gapi: any;
declare const firebase: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  auth2: any;
  id: any;

  constructor(private fuseConfigService: FuseConfigService, private authService: AuthService) {
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        }
      }
    };
  }

  ngOnInit() {
    this.id = setInterval(() => {
      if (gapi && gapi.load) {
        clearInterval(this.id);
        this.initGoogleButton();
      }
    }, 1000);
  }

  initGoogleButton() {
    let _this = this;
    gapi.load('auth2', () => {
      _this.auth2 = gapi.auth2.init({
        client_id: '198208017026-3gifht6hcefrk13tsian714v20ts2e7b.apps.googleusercontent.com',
      });

      let onGoogleLoginSuccess = (googleUser) => {
        let profile = googleUser.getBasicProfile();

        var loginData = {
          "googleId": profile.getId(),
          "googleToken": googleUser.getAuthResponse().id_token,
          "nombre": profile.getName(),
          "correoElectronico": profile.getEmail(),
          "imagen": profile.getImageUrl(),
          "tipoDispositivo": "web"
        };

        _this.loginToFirebaseAndCivixApi(loginData);
      }

      let onGoogleLoginFailure = (error) => {
        console.log(error);
      }

      gapi.signin2.render('googleSignIn', {
        'scope': 'profile email',
        'width': 120,
        'height': 36,
        'theme': 'dark',
        'onsuccess': onGoogleLoginSuccess,
        'onfailure': onGoogleLoginFailure
      });
    });
  }

  loginToFirebaseAndCivixApi(loginData) {
    let _this = this;
    const messaging = firebase.messaging();
    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          messaging.getToken().then((token) => {
            if (token) {
              loginData.firebaseToken = token;
              console.log("<----------Firebase Token---------->");
              console.log(token)
              console.log("<--------Fin Firebase Token--------->");
              _this.authService.login(loginData);
            } else {
              console.log("Error de permisos Firebase. No token.");
            }
          }).catch((err) => {
            console.log(err);
            console.log("Error de permisos Firebase. Exception thrown.");
          });
        } else {
          console.log("Push notifications not allowed.");
          _this.authService.login(loginData);
        }
      });
  }
}
