import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
declare const google: any;

@Injectable()
export class GooglePlacesService {

  intervalId: any;
  elementId: string;
  autocomplete: any;
  currentPositionBounds: any;
  private _autocompleteLocation: BehaviorSubject<any>;
  private _reverseGeocodeResults: BehaviorSubject<any[]>;

  constructor(private ngZone: NgZone, private fuseProgressBarService: FuseProgressBarService) {
    this._autocompleteLocation = new BehaviorSubject({});
    this._reverseGeocodeResults = new BehaviorSubject([]);
  }

  initializePlacesAutocomplete(elementId: string) {
    let _this = this;
    _this.elementId = elementId;
    _this._autocompleteLocation.next({});
    _this._reverseGeocodeResults.next([]);
    _this.intervalId = setInterval(() => {
      if (google && google.maps) {
        clearInterval(_this.intervalId);
        let searchBox = document.getElementById(_this.elementId);
        if (searchBox) {
          _this.autocomplete = new google.maps.places.Autocomplete((document.getElementById(_this.elementId)),
            { fields: ["name", "geometry.location", "formatted_address"] });
          let fillInAddress = () => {
            _this.ngZone.run(() => {
              let place = _this.autocomplete.getPlace();
              if (place && place.geometry && place.geometry.location) {
                let location = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
                _this._autocompleteLocation.next({
                  location: location,
                  formatted_address: place.formatted_address
                });
              } else if (place && place.name) {
                let geocoder = new google.maps.Geocoder();
                _this.fuseProgressBarService.show();
                geocoder.geocode({ address: place.name, bounds: _this.currentPositionBounds}, function (results, status) {
                  _this.fuseProgressBarService.hide();
                  if (status == google.maps.GeocoderStatus.OK && results && results.length > 0) {
                    let result = results[0];
                    if (result && result.geometry && result.geometry.location) {
                      let location = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() };
                      _this.ngZone.run(() => {
                        _this._autocompleteLocation.next({
                          location: location,
                          formatted_address: result.formatted_address
                        });
                      });
                    }
                  }
                });
              }
            });
          }
          _this.autocomplete.addListener('place_changed', fillInAddress);
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
              var geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              var circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy
              });
              _this.currentPositionBounds = circle.getBounds();
              _this.autocomplete.setBounds(circle.getBounds());
            });
          }
        }
      }
    }, 1000);
  }

  getAutocompleteLocation(): Observable<any> {
    return this._autocompleteLocation.asObservable();
  }

  getReverseGeocodeResults(): Observable<any[]> {
    return this._reverseGeocodeResults.asObservable();
  }

  reverseGeocode(lat, lng) {
    let _this = this;
    let latlng = new google.maps.LatLng(lat, lng);
    let geocoder = new google.maps.Geocoder();
    let input = document.getElementById(this.elementId) as HTMLInputElement;
    _this.fuseProgressBarService.show();
    geocoder.geocode({ 'latLng': latlng }, function (results, status) {
      _this.fuseProgressBarService.hide();
      if (status == google.maps.GeocoderStatus.OK) {
        let locationResults = [];
        for (let i = 0; i < results.length; i++) {
          let location = {
            latitude: results[i].geometry.location.lat(),
            longitude: results[i].geometry.location.lng(),
            formatted_address: results[i].formatted_address,
            place_id: results[i].place_id,
            icon: {
              url: "assets/icons/map-markers/blue-point-marker.png",
            }
          };
          locationResults.push(location);
        }
        _this.ngZone.run(() => {
          _this._reverseGeocodeResults.next(locationResults);
          if (results[0]) {
            input.value = results[0].formatted_address;
          }
        });
      }
    });
  }
}
