import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RecaptchaModule } from 'ng-recaptcha';


import locales from '@angular/common/locales/es-MX';
registerLocaleData(locales);

import { AuthGuard } from './auth/auth.guard';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { MAT_HAMMER_OPTIONS } from '@angular/material';
import { AgmCoreModule } from '@agm/core';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseConfirmDialogModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginComponent } from './login/login.component';
import { CrmComponent } from './crm/crm.component';
import { ReportListComponent } from './crm/report-list/report-list.component';
import { registerLocaleData } from '@angular/common';
import { CreateReportComponent } from './crm/report-create/create.report.component';
import { DetailReportComponent } from './crm/report-detail/detail-report.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { AddCommentDialogComponent } from './crm/crm-dialogs/add-comment-dialog/add-comment-dialog.component';
import { ReportFilterComponent } from './crm/report-filter/report-filter.component';
import { ReportListItemComponent } from './crm/report-list-item/report-list-item.component';
import { CloseReportDialogComponent } from './crm/crm-dialogs/close-report-dialog/close-report-dialog.component';
import { AddGroupDialogComponent } from './crm/crm-dialogs/add-group-dialog/add-group-dialog.component';
import { SelectAddressComponent } from './crm/select-address/select-address.component';
import { RelocateReportDialogComponent } from './crm/crm-dialogs/relocate-report-dialog/relocate-report-dialog.component';
import { SwitchObservatorioDialogComponent } from './crm/crm-dialogs/switch-observatorio-dialog/switch-observatorio-dialog.component';
import { CategorizeReportComponent } from './crm/categorize-report/categorize-report.component';
import { EditCategoryDialogComponent } from './crm/crm-dialogs/edit-category-dialog/edit-category-dialog.component';
import { CorreoRelevanteDialogComponent } from './crm/crm-dialogs/correo-relevante-dialog/correo-relevante-dialog.component';
import { ReportsComponent } from './reports/reports.component';

const appRoutes: Routes = [
    {
        path: 'crm', redirectTo: 'crm/', pathMatch: 'full'
    },
    {
        path: 'crm/:folioCic',
        component: CrmComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'crear',
        component: CreateReportComponent,

    },
    {
        path: 'reportes',
        component: ReportsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        component: CreateReportComponent
    }
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        CrmComponent,
        ReportListComponent,
        CreateReportComponent,
        DetailReportComponent,
        ImageDialogComponent,
        AddCommentDialogComponent,
        ReportFilterComponent,
        ReportListItemComponent,
        CloseReportDialogComponent,
        AddGroupDialogComponent,
        SelectAddressComponent,
        RelocateReportDialogComponent,
        SwitchObservatorioDialogComponent,
        CategorizeReportComponent,
        EditCategoryDialogComponent,
        CorreoRelevanteDialogComponent,
        ReportsComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAnrGckM7GOATWuKnVovwaqUH7IO8TVVF8'
        }),
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatRippleModule,
        MatTooltipModule,
        MatStepperModule,
        MatDialogModule,
        MatDatepickerModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatChipsModule,
        OverlayModule,
        MatBadgeModule,
        A11yModule,

        NgxMatSelectSearchModule,
        NgxMaterialTimepickerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseConfirmDialogModule,

        // App modules
        LayoutModule,
        RecaptchaModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: MAT_HAMMER_OPTIONS,
            useValue: { cssProps: { userSelect: true } }
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'es-MX'
        }
    ],
    entryComponents: [ImageDialogComponent, AddCommentDialogComponent, ReportFilterComponent,
        CloseReportDialogComponent, AddGroupDialogComponent, RelocateReportDialogComponent, SwitchObservatorioDialogComponent,
        EditCategoryDialogComponent, CorreoRelevanteDialogComponent]
})
export class AppModule {
}
