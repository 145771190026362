import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CivixApiService } from 'app/civix-api.service';

@Injectable({
  providedIn: 'root'
})
export class CrmCatalogsService {

  temas: Observable<any[]>;
  categorias: Observable<any[]>;
  medios: Observable<any[]>;
  motivosCierre: Observable<any[]>;
  grupos: Observable<any[]>;
  observatoriosCic: Observable<any[]>;


  private _temas: BehaviorSubject<any[]>;
  private _categorias: BehaviorSubject<any[]>;
  private _medios: BehaviorSubject<any[]>;
  private _motivosCierre: BehaviorSubject<any[]>;
  private _grupos: BehaviorSubject<any[]>;
  private _observatoriosCic: BehaviorSubject<any[]>;


  constructor(private civixApiService: CivixApiService) {
    this._temas = new BehaviorSubject([]);
    this._categorias = new BehaviorSubject([]);
    this._medios = new BehaviorSubject([]);
    this._motivosCierre = new BehaviorSubject([]);
    this._grupos = new BehaviorSubject([]);
    this._observatoriosCic = new BehaviorSubject([]);


    if(sessionStorage.login){
      this.initialize();
    }
    this.temas = this._temas.asObservable();
    this.categorias = this._categorias.asObservable();
    this.medios = this._medios.asObservable();
    this.motivosCierre = this._motivosCierre.asObservable();
    this.grupos = this._grupos.asObservable();
    this.observatoriosCic = this._observatoriosCic.asObservable();

   }

   initialize(){
     console.log("initialize");
    this.civixApiService.getTemasCRM(sessionStorage.login).subscribe((data: any) => {
      this._temas.next(data.response);
    });
    this.civixApiService.getCategoriasCRM(sessionStorage.login).subscribe((data: any) => {
      if(data.response && data.response.length > 0){
        data.response = data.response.filter((categoria) => {
          if(categoria.publicado === false){
            return false;
          }else{
            return true;
          }
        });
      }
      this._categorias.next(data.response);
    });
    this.civixApiService.getMediosReporte(sessionStorage.login).subscribe((data: any) => {
      this._medios.next(data.response);
    });
    this.civixApiService.getMotivosCierre(sessionStorage.login).subscribe((data: any) => {
      this._motivosCierre.next(data.response);
    });
    this.civixApiService.getListaGrupos(sessionStorage.login).subscribe((data: any) => {
      this._grupos.next(data.response);
    });
    this.civixApiService.getObservatoriosDisponibles(sessionStorage.login).subscribe((data: any) => {
      this._observatoriosCic.next(data.response);
    });
    
  
   }
}
