import { Component, OnInit, ViewChildren, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import { CivixApiService } from '../civix-api.service';
import { Report } from './report-list/report.model';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { GooglePlacesService } from 'app/google-places.service';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, take } from 'rxjs/operators';
import { AgmMarker } from '@agm/core';
import { CrmStateService } from './crm-state.service';
import { MatSnackBar, MatButton } from '@angular/material';
import { MapTypeControlOptions, ControlPosition } from '@agm/core/services/google-maps-types';
import { Overlay, FlexibleConnectedPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ReportFilterComponent } from './report-filter/report-filter.component';
import { googleMapDarkModeStyles } from 'app/fuse-config/google-map-dark-mode-styles';
import { FuseConfigService } from '@fuse/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from 'app/app-state.service';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss'],
  providers: [{ provide: CrmStateService, useClass: CrmStateService }, { provide: GooglePlacesService, useClass: GooglePlacesService }]
})
export class CrmComponent implements OnInit {

  mapCenter: any = {};
  markerCenter: any = {};
  markers: any[] = [];
  reportList: Report[] = [];
  showingMap: boolean;
  loadingMoreReports: boolean = false;
  refreshingReports: boolean = false;
  selectedMode: string = '';
  filterSettings: any;
  @ViewChildren(AgmMarker) agmMarkers: QueryList<AgmMarker>;
  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_RIGHT
  };
  @ViewChild('filterButton', { static: false }) filterButtonElement: MatButton;
  filterPaneIsOpen: boolean = false;
  appliedFilterCount: number = 0;
  mapStyles: any[] = [];
  loadingFromUrl = false;

  private _unsubscribeAll: Subject<any>;
  private _interruptLoadingReports: Subject<any>;
  private _filterOverlayRef: OverlayRef;
  private filterPositionStrategy: FlexibleConnectedPositionStrategy;

  constructor(private civixApiService: CivixApiService, private fuseProgressBarService: FuseProgressBarService, private _fuseConfigService: FuseConfigService,
    private overlay: Overlay, private googlePlacesService: GooglePlacesService, private crmStateService: CrmStateService, private _snackBar: MatSnackBar,
    private viewContainerRef: ViewContainerRef, private route: ActivatedRoute, private router: Router, private appStateService: AppStateService) {
    this._unsubscribeAll = new Subject();
    this._interruptLoadingReports = new Subject();
  }

  ngOnInit() {
    console.log('init');
    let _this = this;
    _this.showingMap = true;
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        if (settings.colorTheme === 'theme-blue-gray-dark') {
          _this.mapStyles = googleMapDarkModeStyles;
        } else {
          _this.mapStyles = [];
        }
      });
    this.crmStateService.setFilterMode('despacho');
    this.crmStateService.filterMode
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((mode) => {
        this.selectedMode = mode;
      });
    _this.route.paramMap
      .pipe(take(1), takeUntil(this._unsubscribeAll))
      .subscribe((params) => {
        let folioCic = params.get('folioCic');
        if (folioCic) {
          let filterSettings = _this.crmStateService.getFilterSettings();
          filterSettings.palabraClave = folioCic;
          _this.crmStateService.setFilterSettings(filterSettings);
          this.loadingFromUrl = true;
        }
      });
    let loadedFromUrl = false;
    this.crmStateService.filterSettings
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((filterSettings) => {
        //user changed filter after loading report detail from URL. so now allow scrolling for new reports
        if (loadedFromUrl) {
          _this.loadingFromUrl = false;
        }
        _this.filterSettings = filterSettings;
        _this.calculateFilterCount();
        if (_this._filterOverlayRef && _this._filterOverlayRef.hasAttached()) {
          _this._filterOverlayRef.detach();
          _this.filterPaneIsOpen = false;
        }
        if (!_this.loadingFromUrl) {
          _this.getReportList(filterSettings, null);
        } else {
          loadedFromUrl = true;
          _this.getReportList(filterSettings, filterSettings.palabraClave);
        }
      });
    _this.googlePlacesService.initializePlacesAutocomplete('searchBox');
    _this.googlePlacesService.getAutocompleteLocation()
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((place: any) => {
        if (place.location && place.location.lat) {
          _this.mapCenter.latitud = place.location.lat;
          _this.mapCenter.longitud = place.location.lng;
          _this.markerCenter = _this.mapCenter;
        }
      });
    //debounce keyboard navigation observer
    _this.crmStateService.currentReport
      .pipe(debounceTime(500), takeUntil(_this._unsubscribeAll))
      .subscribe((report) => {
        if (report && report['_senderComponent'] === 'reportListItemComponent') {
          _this.updateSelectedMarker(report);
        }
        if (report) {
          this.router.navigate(['/crm/' + report.folioCic]);
        }
      });
    _this.crmStateService.currentReport
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((report) => {
        if (report && report['_senderComponent'] !== 'reportListItemComponent') {
          _this.updateSelectedMarker(report);
          if (report['_senderComponent'] === 'detailReport_relocate') {
            _this.updateReportCoordinates(report);
          }
        }
      });
    _this.appStateService.usuario
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((usuario) => {
        if (usuario) {
          //     _this.getReportList(_this.filterSettings, null);
        }
      });
  }

  ngOnDestroy() {
    console.log('destroy');
    this._filterOverlayRef.dispose();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit() {
    this.setUpFilter();
  }

  getReportList(filterSettings, folioCicUrl: string) {
    let _this = this;
    _this.loadingMoreReports = false;
    _this._interruptLoadingReports.next();
    _this.crmStateService.setReportsPage(0);
    _this.fuseProgressBarService.show();
    _this.refreshingReports = true;
    _this.civixApiService.getCoordenadaCentral(sessionStorage.login)
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((data: any) => {
        if (!_this.markerCenter.latitud) {
          _this.mapCenter = data.response;
          _this.markerCenter = data.response;
        }
        let filterMode = _this.selectedMode === 'reportes-cercanos' ? 'monitoreo' : 'despacho';
        let orden = filterMode === 'despacho' ? 'fecha' : 'distancia';
        _this.civixApiService.getReportesFiltradosCRM({
          folioCic: filterSettings.folioCic,
          temaId: filterSettings.tema.id,
          categoriaId: filterSettings.categoria._id,
          palabraClave: filterSettings.palabraClave,
          fechaDesde: filterSettings.fechaDesde,
          fechaHasta: filterSettings.fechaHasta,
          estatus: filterSettings.estatus,
          medio: filterSettings.medio.nombre,
          filtro: filterMode,
          latitud: _this.mapCenter.latitud,
          longitud: _this.mapCenter.longitud,
          orden: orden,
          token: sessionStorage.login
        }).pipe(takeUntil(_this._interruptLoadingReports), takeUntil(_this._unsubscribeAll))
          .subscribe((data: any) => {
            _this.fuseProgressBarService.hide();
            _this.refreshingReports = false;
            if (_this.validateReportsResponseDespacho(data)) {
              _this.updateReportList(data.response, false);
              if (folioCicUrl) {
                setTimeout(() => {
                  _this.viewReport({
                    folioCic: folioCicUrl
                  });
                }, 200);
              }
            }
          }, (error) => {
            _this._snackBar.open('No se encontraron reportes.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
            console.log(error);
          });
      });
  }

  mapClick($event) {
    let _this = this;
    _this.crmStateService.setFilterMode('reportes-cercanos');
    _this.loadingMoreReports = false;
    _this.refreshingReports = false;
    _this._interruptLoadingReports.next();
    _this.markerCenter = {
      latitud: $event.coords.lat,
      longitud: $event.coords.lng
    };
    _this.fuseProgressBarService.show();
    _this.civixApiService.getReportesFiltradosCRM({
      folioCic: _this.filterSettings.folioCic,
      temaId: _this.filterSettings.tema.id,
      categoriaId: _this.filterSettings.categoria._id,
      palabraClave: _this.filterSettings.palabraClave,
      fechaDesde: _this.filterSettings.fechaDesde,
      fechaHasta: _this.filterSettings.fechaHasta,
      estatus: _this.filterSettings.estatus,
      medio: _this.filterSettings.medio.nombre,
      filtro: 'monitoreo',
      latitud: $event.coords.lat,
      longitud: $event.coords.lng,
      orden: 'distancia',
      token: sessionStorage.login
    }).pipe(takeUntil(_this._interruptLoadingReports), takeUntil(_this._unsubscribeAll))
      .subscribe((data: any) => {
        _this.fuseProgressBarService.hide();
        if (_this.validateReportsResponse(data)) {
          _this.updateReportList(data.response, false);
        }
      });
  }

  validateReportsResponse(data) {
    if (data && data.response && data.response.length > 0) {
      return true;
    } else {
      console.log('error getting reports', data);
      this._snackBar.open('No se encontraron reportes.', 'Ok', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 2000
      });
      return false;
    }
  }

  validateReportsResponseDespacho(data) {
    //if (data && data.response && data.response[0] && data.response[0].reportes && data.response[0].reportes.length > 0) {
    if (data && data.response && data.response.length > 0) {
      return true;
    } else {
      if (data && data.response && data.response.length === 0) {
        this.crmStateService.bounceReportList();
      }
      console.log('error getting reports', data);
      this._snackBar.open('No se encontraron reportes.', 'Ok', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 2000
      });
      return false;
    }
  }

  updateReportList(reportList: Report[], addToList: boolean) {
    let _this = this;
    let markers = [];
    for (let i = 0; i < reportList.length; i++) {
      let marker = {
        folioCic: reportList[i].folioCic,
        categoriaNombre: reportList[i].categoriaNombre,
        iconUrl: {
          url: reportList[i].icono,
          scaledSize: {
            width: 33,
            height: 33
          }
        },
        latitude: reportList[i].geo.coordinates[1],
        longitude: reportList[i].geo.coordinates[0],
        description: reportList[i].descripcion
      };
      markers.push(marker);
    }
    if (addToList) {
      _this.reportList = _this.reportList.concat(reportList);
      _this.markers = _this.markers.concat(markers);
    } else {
      _this.reportList = reportList;
      _this.markers = markers;
    }

  }

  showMap() {
    this.showingMap = true;
  }

  hideMap() {
    this.showingMap = false;
  }

  markerClick(clickedMarker, marker) {
    if (this.agmMarkers) {
      let markers = this.agmMarkers.toArray();
      for (let i = 0; i < markers.length; i++) {
        let infoWindows = markers[i].infoWindow.toArray();
        for (let j = 0; j < infoWindows.length; j++) {
          infoWindows[j].close();
        }
      }
    }
    let infoWindows = clickedMarker.infoWindow.toArray();
    if (infoWindows[0]) {
      infoWindows[0].open();
    }
  }

  getMoreReports() {
    let _this = this;
    if (_this.selectedMode !== 'reportes-cercanos' && !_this.loadingMoreReports && !_this.refreshingReports && !_this.loadingFromUrl) {
      _this.loadingMoreReports = true;
      _this.crmStateService.setReportsPage(_this.crmStateService.getReportsPage() + 1);
      _this.civixApiService.getReportesFiltradosCRM({
        folioCic: _this.filterSettings.folioCic,
        temaId: _this.filterSettings.tema.id,
        categoriaId: _this.filterSettings.categoria._id,
        palabraClave: _this.filterSettings.palabraClave,
        fechaDesde: _this.filterSettings.fechaDesde,
        fechaHasta: _this.filterSettings.fechaHasta,
        estatus: _this.filterSettings.estatus,
        medio: _this.filterSettings.medio.nombre,
        filtro: 'despacho',
        latitud: _this.mapCenter.latitud,
        longitud: _this.mapCenter.longitud,
        orden: 'fecha',
        token: sessionStorage.login,
        page: _this.crmStateService.getReportsPage()
      }).pipe(takeUntil(_this._interruptLoadingReports), takeUntil(_this._unsubscribeAll))
        .subscribe((data: any) => {
          if (_this.validateReportsResponseDespacho(data)) {
            let reportes = data.response;
            reportes = _this.filterNewerOrRepeatedReports(reportes);
            if (reportes.length === 0) {
              _this.loadingMoreReports = false;
              _this.getMoreReports();
            } else {
              _this.updateReportList(reportes, true);
              _this.loadingMoreReports = false;
            }
          } else {
            _this.crmStateService.setReportsPage(_this.crmStateService.getReportsPage() - 1);
            _this.loadingMoreReports = false;
          }
        });
    }
  }

  filterNewerOrRepeatedReports(reportes: Report[]) {
    let _this = this;
    if (_this.reportList.length > 0) {
      let lastDate = this.reportList[_this.reportList.length - 1].createdAt;
      return reportes.filter((report, index, array) => {
        let earlierDate = false;
        let duplicate = false;
        if (report.createdAt <= lastDate) {
          earlierDate = true;
          for (let i = 0; i < _this.reportList.length; i++) {
            if (report._id === _this.reportList[i]._id) {
              duplicate = true;
            }
          }
        }
        if (earlierDate && !duplicate) {
          return report;
        }
      });
    } else {
      return reportes;
    }
  }

  selectFilterMode(mode: string) {
    let _this = this;
    _this.loadingMoreReports = false;
    _this._interruptLoadingReports.next();
    _this.refreshingReports = false;
    _this.crmStateService.setFilterMode(mode);
    _this.crmStateService.setReportsPage(0);
    if (mode === 'reportes-cercanos') {
      _this.fuseProgressBarService.show();
      _this.civixApiService.getReportesFiltradosCRM({
        folioCic: _this.filterSettings.folioCic,
        temaId: _this.filterSettings.tema.id,
        categoriaId: _this.filterSettings.categoria._id,
        palabraClave: _this.filterSettings.palabraClave,
        fechaDesde: _this.filterSettings.fechaDesde,
        fechaHasta: _this.filterSettings.fechaHasta,
        estatus: _this.filterSettings.estatus,
        medio: _this.filterSettings.medio.nombre,
        filtro: 'monitoreo',
        latitud: _this.markerCenter.latitud,
        longitud: _this.markerCenter.longitud,
        orden: 'distancia',
        token: sessionStorage.login
      }).pipe(takeUntil(_this._interruptLoadingReports), takeUntil(_this._unsubscribeAll))
        .subscribe((data: any) => {
          _this.fuseProgressBarService.hide();
          if (_this.validateReportsResponse(data)) {
            _this.updateReportList(data.response, false);
          }
        });
    } else if (mode === 'despacho') {
      _this.refreshingReports = true;
      _this.fuseProgressBarService.show();
      _this.civixApiService.getReportesFiltradosCRM({
        folioCic: _this.filterSettings.folioCic,
        temaId: _this.filterSettings.tema.id,
        categoriaId: _this.filterSettings.categoria._id,
        palabraClave: _this.filterSettings.palabraClave,
        fechaDesde: _this.filterSettings.fechaDesde,
        fechaHasta: _this.filterSettings.fechaHasta,
        estatus: _this.filterSettings.estatus,
        medio: _this.filterSettings.medio.nombre,
        filtro: 'despacho',
        latitud: _this.mapCenter.latitud,
        longitud: _this.mapCenter.longitud,
        orden: 'fecha',
        token: sessionStorage.login,
        page: _this.crmStateService.getReportsPage()
      }).pipe(takeUntil(_this._interruptLoadingReports), takeUntil(_this._unsubscribeAll))
        .subscribe((data: any) => {
          _this.fuseProgressBarService.hide();
          _this.refreshingReports = false;
          if (_this.validateReportsResponseDespacho(data)) {
            _this.updateReportList(data.response, false);
          }
        });
    }
  }

  updateSelectedMarker(report: Report) {
    let _this = this;

    if (!report) {
      return;
    }

    let panTo = {
      latitud: report.geo.coordinates[1],
      longitud: report.geo.coordinates[0]
    };
    _this.mapCenter = panTo;
    for (let i = 0; i < _this.markers.length; i++) {
      if (_this.markers[i].folioCic === report.folioCic) {
        let marker = {
          folioCic: report.folioCic,
          categoriaNombre: report.categoriaNombre,
          iconUrl: {
            url: report.icono,
            scaledSize: {
              width: 33,
              height: 33
            }
          },
          latitude: report.geo.coordinates[1],
          longitude: report.geo.coordinates[0],
          description: report.descripcion,
          animation: 'BOUNCE'
        };
        _this.markers[i] = marker;
      } else {
        _this.markers[i].animation = null;
      }
    }
  }

  viewReport(marker) {
    let report = this.reportList.find((report, i, array) => {
      if (marker.folioCic === report.folioCic) {
        return true;
      }
    });

    if (report) {
      report['_senderComponent'] = 'crmComponent';
    }
    this.crmStateService.setCurrentReport(report);
  }

  setUpFilter() {
    let _this = this;
    this.filterPositionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.filterButtonElement._elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 6
        }
      ]);
    this._filterOverlayRef = this.overlay.create({
      positionStrategy: this.filterPositionStrategy,
      disposeOnNavigation: true,
      hasBackdrop: true,
      backdropClass: 'report-filter-backdrop'
    });
    this._filterOverlayRef.backdropClick()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this._filterOverlayRef.hasAttached()) {
          _this._filterOverlayRef.detach();
          _this.filterPaneIsOpen = false;
        }
      });
  }

  toggleFilter() {
    if (this._filterOverlayRef.hasAttached()) {
      this._filterOverlayRef.detach();
      this.filterPaneIsOpen = false;
    } else {
      let reportFilterPortal = new ComponentPortal(ReportFilterComponent, this.viewContainerRef);
      this._filterOverlayRef.attach(reportFilterPortal);
      this.filterPaneIsOpen = true;
    }
  }

  calculateFilterCount() {
    let count = 0;
    if (this.filterSettings.folioCic) {
      count++;
    }
    if (this.filterSettings.palabraClave) {
      count++
    }
    if (this.filterSettings.tema) {
      count++
    }
    if (this.filterSettings.categoria) {
      count++
    }
    if (this.filterSettings.estatus) {
      count++
    }
    if (this.filterSettings.medio) {
      count++
    }
    if (this.filterSettings.fechaDesde) {
      count++
    }
    if (this.filterSettings.fechaHasta) {
      count++;
    }
    this.appliedFilterCount = count;
  }

  updateReportCoordinates(report: Report) {
    let _this = this;
    let updatedReport = _this.reportList.find((r, i, reports) => {
      if (r.folioCic === report.folioCic) {
        return true;
      }
    });
    updatedReport.geo = report.geo;
  }
}