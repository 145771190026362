import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MapTypeControlOptions, ControlPosition } from '@agm/core/services/google-maps-types';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { googleMapDarkModeStyles } from 'app/fuse-config/google-map-dark-mode-styles';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CivixApiService } from 'app/civix-api.service';
import { GooglePlacesService } from 'app/google-places.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.scss'],
  animations: fuseAnimations,
  providers: [{ provide: GooglePlacesService, useClass: GooglePlacesService }]
})
export class SelectAddressComponent implements OnInit {

  @Output() addressChange = new EventEmitter<any>();
  @Input() mapCenter: any = {};
  markerCenter: any = {};
  reverseGeocodeResults: any[];
  selectingAddress: boolean = true;
  mapStyles: any[] = [];
  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_RIGHT
  };
  formattedAddress = new FormControl('');
  @Input() currentAddress: string;
  private _unsubscribeAll: Subject<any>;

  constructor(private _fuseConfigService: FuseConfigService, private fuseProgressBarService: FuseProgressBarService, private civixApiService: CivixApiService,
    private googlePlacesService: GooglePlacesService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    let _this = this;
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        if (settings.colorTheme === 'theme-blue-gray-dark') {
          _this.mapStyles = googleMapDarkModeStyles;
        } else {
          _this.mapStyles = [];
        }
      });

    this.formattedAddress.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((change) => {
        if (_this.markerCenter.latitud) {
          let address = {
            formattedAddress: change,
            coordinates: _this.markerCenter
          }
          _this.addressChange.emit(address);
        }
      });
    if (!this.mapCenter.latitud) {
      setTimeout(() => {
        _this.fuseProgressBarService.show();
        _this.civixApiService.getCoordenadaCentral(sessionStorage.login).pipe(takeUntil(_this._unsubscribeAll))
          .subscribe((data: any) => {
            _this.fuseProgressBarService.hide();
            _this.mapCenter = data.response;
          });
      }, 0);
    } else {
      this.markerCenter = this.mapCenter;
      if (this.currentAddress) {
        this.formattedAddress.setValue(this.currentAddress, { emitEvent: false });
      }
    }
    this.googlePlacesService.initializePlacesAutocomplete('searchBox-select-address');
    this.googlePlacesService.getAutocompleteLocation()
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((place) => {
        if (place.location && place.location.lat) {
          _this.mapCenter.latitud = place.location.lat;
          _this.mapCenter.longitud = place.location.lng;
          _this.markerCenter = _this.mapCenter;
          _this.selectingAddress = false;
          let address = {
            formattedAddress: place.formatted_address,
            coordinates: _this.markerCenter
          }
          _this.formattedAddress.setValue(address.formattedAddress, { emitEvent: false });
          _this.addressChange.emit(address);
        }
      });
    this.googlePlacesService.getReverseGeocodeResults()
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((results) => {
        if (results.length > 0) {
          _this.reverseGeocodeResults = results;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  mapClick($event) {
    let _this = this;
    _this.markerCenter = {
      latitud: $event.coords.lat,
      longitud: $event.coords.lng
    };
    _this.googlePlacesService.reverseGeocode(_this.markerCenter.latitud, _this.markerCenter.longitud);
  }

  hoverReverseGeoResult($event) {
    for (let i = 0; i < this.reverseGeocodeResults.length; i++) {
      let result = this.reverseGeocodeResults[i];
      if (result.place_id === $event.target.dataset.placeId) {
        result.icon = {
          url: "assets/icons/map-markers/blue-paddle-marker.png"
        };
      } else {
        result.icon = {
          url: "assets/icons/map-markers/blue-point-marker.png"
        };
      }
    }
  }

  mouseOutReverseGeoResult($event) {
    for (let i = 0; i < this.reverseGeocodeResults.length; i++) {
      let result = this.reverseGeocodeResults[i];
      result.icon = {
        url: "assets/icons/map-markers/blue-point-marker.png"
      };
    }
  }

  selectReverseGeoAddress(address) {
    let _this = this;
    this.selectingAddress = false;
    this.reverseGeocodeResults = [];
    let addressObject = {
      formattedAddress: address,
      coordinates: _this.markerCenter
    }
    _this.formattedAddress.setValue(addressObject.formattedAddress, { emitEvent: false });
    _this.addressChange.emit(addressObject);
    (document.getElementById('searchBox-select-address') as any).value = '';
  }

}
