import { Injectable } from '@angular/core';
import { CivixApiService } from './civix-api.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  usuario: Observable<any>;
  private _usuario: BehaviorSubject<any>;

  constructor(private civixApiService: CivixApiService) {

    this._usuario = new BehaviorSubject(null);
    if(sessionStorage.login){
      this.initialize();
    }
    this.usuario = this._usuario.asObservable();
   }

   initialize(){
    this.civixApiService.getUsuarioPropio(sessionStorage.login).subscribe((data: any) => {
      this._usuario.next(data.response);
    });
   }

   setUserData(user){
     let newData = Object.assign({}, user);
     this._usuario.next(newData);
   }
}
