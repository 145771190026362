import { Component, OnInit, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CivixApiService } from 'app/civix-api.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-category-dialog',
  templateUrl: './edit-category-dialog.component.html',
  styleUrls: ['./edit-category-dialog.component.scss']
})
export class EditCategoryDialogComponent implements OnInit {

  readyToSend: boolean = false;
  categoriaId: string;
  tags: string[];
  private _unsubscribeAll: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditCategoryDialogComponent>,
    private civixApiService: CivixApiService, private _snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateCategoryData($event) {
    if ($event.categoriaId) {
      this.readyToSend = true;
    } else {
      this.readyToSend = false;
    }
    this.categoriaId = $event.categoriaId;
    this.tags = $event.tags;
  }

  recategorizeReport() {
    let _this = this;
    if (this.categoriaId) {
      let data = {
        token: sessionStorage.login,
        folioCic: this.data.folioCic,
        categoriaId: this.categoriaId,
        tags: this.tags || []
      };
      this.civixApiService.reasignarCategoria(data)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
          if (response && response.response && response.response.status === 'OK') {
            _this.dialogRef.close(true);
            _this._snackBar.open('Reporte actualizado.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          } else {
            _this.dialogRef.close(false);
            _this._snackBar.open('Error al reasignar categoría.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          }
        });
    }
  }
}
