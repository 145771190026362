import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CivixApiService {

  constructor(private http: HttpClient) {

  }

  login(loginData: any) {
    console.log("login");
    return this.http.post(environment.apiUrl + 'api/Usuarios/login', loginData);
  }

  getArbolModulos(token: string) {
    let options = {
      params: new HttpParams().set('token', token)
    };
    return this.http.get(environment.apiUrl + 'api/Modulos/getArbolModulos', options);
  }

  getCoordenadaCentral(token: string) {
    console.log("this.getCoordenadaCentral");
    this.http.post(environment.apiUrl + 'api/Botons/visitaExterna', {data:"hola23"});
    return this.http.post(environment.apiUrl + 'api/Usuarios/getCoordenadaCentral', {
      'token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU"
    });
  }

  getReportesFiltradosCRM(parametros: any) {
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/getReportesFiltradosCRM2', {
      'token': parametros.token,
      'direccion': 'asc',
      'filtro': parametros.filtro,
      'latitud': parametros.latitud,
      'limit': 20,
      'longitud': parametros.longitud,
      'orden': parametros.orden,
      'page': parametros.page || 0,
      'radio': 2000,
      'categoriaId': parametros.categoriaId || '',
      'estatus': parametros.estatus || '',
      'fechaFin': parametros.fechaHasta || new Date(),
      'fechaInicio': parametros.fechaDesde || new Date((new Date().getTime() - (30 * 24 * 60 * 60 * 1000))),
      'keyword': parametros.palabraClave || '',
      'medioReporte': parametros.medio || '',
      'temaId': parametros.temaId || ''
    });
  }

  getReporteCRM(token, reporteId) {
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/getReporteCRM', {
      geoPuntoId: reporteId,
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU"
    });
  }

  getTemasCRM(token) {
    let options = {
      params: new HttpParams().set('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU").set('soloDeGrupoSolucion', 'true')
    };
    return this.http.get(environment.apiUrl + 'api/Temas/getTemasCRM', options);
  }

  getMediosReporte(token) {
    return this.http.post(environment.apiUrl + 'api/MedioReportes/getMediosReporte', {
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU"
    });
  }

  getCategoriasCRM(token) {
    let options = {
      params: new HttpParams().set('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU").set('soloDeGrupoSolucion', 'true')
    };
    return this.http.get(environment.apiUrl + 'api/Categoria/getCategorias', options);
  }

  getCategoriasParaUbicacion(token, location) {
    let options = {
      params: new HttpParams()
        .set('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU")
        .set('latitud', location.latitud)
        .set('longitud', location.longitud)
    };
    return this.http.get(environment.apiUrl + 'api/Categoria/getCategoriasUbicacion', options);
  }

  getTemasParaUbicacion(token, location) {
    let options = {
      params: new HttpParams()
        .set('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU")
        .set('latitud', location.latitud)
        .set('longitud', location.longitud)
    };
    return this.http.get(environment.apiUrl + 'api/Temas/getTemasUbicacion', options);
  }

  crearReporteCRM2(reportData) {
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/crearReporteCRM2', reportData);
  }

  crearNotaReporte(data) {
    return this.http.post(environment.apiUrl + 'api/ComentarioReportes/crearNotaReporte', data);
  }

  crearSeguimientoReporte(data) {
    return this.http.post(environment.apiUrl + 'api/ComentarioReportes/crearSeguimientoReporte', data);
  }

  getMotivosCierre(token){
    return this.http.post(environment.apiUrl + 'api/MotivoCierres/getMotivosCierre', {
      token: token
    });
  }

  cerrarReporte(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/cerrarReporte', data);
  }

  cambiarEstatusReporte(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/cambiarEstatus', data);
  }

  getListaGrupos(token){
    return this.http.post(environment.apiUrl + 'api/Grupos/listaGrupos', {
      token: token
    });
  }

  reasignarGrupo(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/reasignarGrupo', data);
  }

  actualizarUbicacion(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/actualizarUbicacion', data);
  }

  getObservatoriosDisponibles(token){
    return this.http.post(environment.apiUrl + 'api/Observatorios/getObservatoriosDisponibles',  {
      token: token
    });
  }

  getUsuarioPropio(token) {
    let options = {
      params: new HttpParams().set('token', token)
    };
    return this.http.get(environment.apiUrl + 'api/Usuarios/getUsuarioPropio', options);
  }

  getGruposObservatorio(token, observatorioId){
    return this.http.post(environment.apiUrl + 'api/Observatorios/getGrupos',  {
      token: token,
      observatorioId: observatorioId
    });
  }

  editarUsuario(data){
    return this.http.post(environment.apiUrl + 'api/Usuarios/editarUsuario', data);
  }

  editarFolioExterno(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/editarFolioExterno', data);
  }

  reasignarCategoria(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/reasignarCategoriaAPI', data);
  }

  editarDescripcion(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/editarDescripcion', data);
  }

  getObservatorioUsuario(data){
    return this.http.post(environment.apiUrl + 'api/Observatorios/getObservatorioUsuario', data);
  }

  enviarCorreosReporteRelevante(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/enviarCorreos', data);
  }

  generarReporteGrupoSolucionCSV(data){
    return this.http.post(environment.apiUrl + 'api/GeoPuntos/getReportesCSVCRM', data);
  }

  visitaExterna(data){
    console.log("servicio visita externa");
    
    return this.http.post(environment.apiUrl + 'api/Botons/visitaExterna', {nombre:data});
  }
}
