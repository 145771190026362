import { Component, OnInit, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CivixApiService } from 'app/civix-api.service';
import { takeUntil } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-correo-relevante-dialog',
  templateUrl: './correo-relevante-dialog.component.html',
  styleUrls: ['./correo-relevante-dialog.component.scss']
})
export class CorreoRelevanteDialogComponent implements OnInit {

  asunto = new FormControl('', Validators.required);
  descripcion = new FormControl('', Validators.required);
  private _unsubscribeAll: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CorreoRelevanteDialogComponent>,
    private civixApiService: CivixApiService, private _snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    let _this = this;
    _this.civixApiService.getObservatorioUsuario({
      token: sessionStorage.login
    }).pipe(takeUntil(_this._unsubscribeAll))
    .subscribe((response: any) => {
      if (response && response.response && response.response.nombreLista) {
        _this.asunto.setValue(response.response.nombreLista + ' - ' + _this.data.reporte.categoriaNombre);
      }else {
        _this.asunto.setValue('Reporte CIVIX - ' + _this.data.reporte.categoriaNombre);
      }
    });
    _this.descripcion.setValue(_this.data.reporte.descripcion);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  enviarCorreos(){
    let _this = this;
    _this.asunto.markAsTouched();
    _this.descripcion.markAsTouched()
    if (_this.data && _this.data.reporte && _this.data.reporte._id && !_this.asunto.invalid && !_this.descripcion.invalid) {
      let data = {
        token: sessionStorage.login,
        geoPuntoId: _this.data.reporte._id,
        asunto: _this.asunto.value,
        descripcion: _this.descripcion.value,
        fechaEvento: new Date(_this.data.reporte.fechaEvento).toLocaleString()
      };
        this.civixApiService.enviarCorreosReporteRelevante(data)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
           if (response && response.response  === 'ok') {
            _this.dialogRef.close(true);
            _this._snackBar.open('Correos enviados.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          } else {
            console.log(response);
            _this._snackBar.open('Error al enviar correos.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          } 
        }, (error) => {
          console.log(error);
          _this._snackBar.open('Error al enviar correos.', 'Ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000
          });
        });
    }
  }
}
