import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CivixApiService } from 'app/civix-api.service';
import { takeUntil } from 'rxjs/operators';
import { CrmCatalogsService } from '../crm-catalogs.service';
import { CorreoRelevanteDialogComponent } from '../crm-dialogs/correo-relevante-dialog/correo-relevante-dialog.component';
import { ActivatedRoute } from '@angular/router';

declare const AWS: any;

@Component({
  selector: 'app-create-report',
  templateUrl: './create.report.component.html',
  styleUrls: ['./create.report.component.scss'],
  animations: fuseAnimations
})
export class CreateReportComponent implements OnInit {
  horizontalStepperStep1: FormGroup;
  horizontalStepperStep2: FormGroup;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  tags: any[] = [];
  medios: any[];
  categorias: any[];
  visitaExterna:any[];
  images: any[] = [];
  s3_urls: string[] = [];
  droppingFile: boolean = false;
  locationNotSelected: boolean = false;
  inputsDisabled: boolean = false;
  order: string;
  visita:string;

  private _unsubscribeAll: Subject<any>;

  constructor(private _ngZone: NgZone, private _formBuilder: FormBuilder, private fuseProgressBarService: FuseProgressBarService,
    private civixApiService: CivixApiService, private crmCatalogsService: CrmCatalogsService, private _matDialog: MatDialog,
    private router: Router, private _snackBar: MatSnackBar,private route: ActivatedRoute) {
      
      this.route.queryParams.subscribe(params => {
        this.visita = params['id'];
        sessionStorage.setItem('visita', this.visita);
        
        //console.log(this.vs); // Print the parameter to the console. 
    });

    this._unsubscribeAll = new Subject();
  }



  

  
  ngOnInit(): void {
    this.horizontalStepperStep1 = this._formBuilder.group({
      coordinates: [null, Validators.required],
      formattedAddress: ['', Validators.required]
    });

    this.horizontalStepperStep2 = this._formBuilder.group({
      categoriaId: [null, Validators.required],
      fecha: [new Date()],
      anonimo: [false],
      descripcion: ['', Validators.required],
      clasificacion: ['', Validators.required],
      medio: [null, Validators.required],
      relevante: false,
      email: '',
      nombreUsuario: '',
      telefono: '',
      fileInput: null,
      referenciaMedio: '',
      facebook: '',
      twitter: '',
      whatsapp: ''
    });
    this.crmCatalogsService.medios
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(medios => {
        console.log(medios);
        this.medios = medios;
      });
    this.crmCatalogsService.categorias
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(categorias => {
        this.categorias = categorias;
      });



      setTimeout(() => {
        let _this=this;
        _this.civixApiService.visitaExterna({nombre:"hola2"}).pipe(takeUntil(_this._unsubscribeAll))
         console.log("set time out visita externa");
      }, 0);


 

      
      

      //this.router.navigate(['/order'], { queryParams: { order: 'popular' } });


    //   this.route.queryParams
    //   .subscribe(params => {
    //     console.log(params); // { order: "popular" }

    //     this.order = params.order;
    //     console.log(this.order); // popular
    //   }
    // );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  discardChanges() {
    if (this.horizontalStepperStep1.pristine && this.horizontalStepperStep2.pristine) {
      this.router.navigate(['/crear']);
    } else {
      this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: false,
        data: {
          disableCancelar: false
        }
      });
      this.confirmDialogRef.componentInstance.confirmTitle = "Confirmar";
      this.confirmDialogRef.componentInstance.confirmMessage = '¿Descartar cambios del nuevo reporte?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/crear']);
        }
        this.confirmDialogRef = null;
      });
    }
  }

  fileChange($event) {
    let _this = this;
    let targetElement = ($event.target as HTMLInputElement);
    if (targetElement.files) {
      let fileCount = targetElement.files.length;
      for (let i = 0; i < targetElement.files.length; i++) {
        if (targetElement.files[i].type.includes('image/')) {
          let reader = new FileReader();
          reader.readAsDataURL(targetElement.files[i]);
          reader.onload = (event) => {
            let image = {
              url: reader.result,
              file: targetElement.files[i]
            };
            _this.images.push(image);
            fileCount--;
            if (fileCount === 0) {
              targetElement.value = '';
            }
          }
        }
      }
    }
  }








  createReport() {
    console.log("createReport");
    let _this = this;
    this.horizontalStepperStep2.markAllAsTouched();
    if (!this.horizontalStepperStep2.valid) {
      let reportData = {
        token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjg3OTkwMDg4MzM1LCJkYXRhIjp7InVzdWFyaW9JZCI6IjU5YTA0YWJjNDY2NzQ0MDAxMDA4NzU2MyIsInBlcmZpbElkIjoiNTk0ZDliYmY0Njk0NmI4NmUzMmM2NGIwIn0sImlhdCI6MTU5MDA4ODMzNX0.s2WUwOKifRPPDtg-WEQTUDY6PU1_WKAS0rcHK9Yh0WU",
        categoriaId: this.horizontalStepperStep2.get('categoriaId').value,
        descripcion: this.horizontalStepperStep2.get('descripcion').value,
        direccionTextual: this.horizontalStepperStep1.get('formattedAddress').value,
        estatusVisible: true,
        fechaEvento: this.horizontalStepperStep2.get('fecha').value,
        fuente: this.horizontalStepperStep2.get('clasificacion').value,
        geo: {
          coordinates: [
            _this.horizontalStepperStep1.get('coordinates').value.longitud,
            _this.horizontalStepperStep1.get('coordinates').value.latitud
          ],
          type: 'Point'
        },
        medioReporteId: "6076ed22f137f574f3338be5",
        relevante: false,
        usuario: {
          email: _this.horizontalStepperStep2.get('email').value,
          nombre: _this.horizontalStepperStep2.get('nombreUsuario').value,
          referencia: _this.horizontalStepperStep2.get('referenciaMedio').value,
          telefono: _this.horizontalStepperStep2.get('telefono').value,
          facebook: _this.horizontalStepperStep2.get('facebook').value,
          twitter: _this.horizontalStepperStep2.get('twitter').value
        },
        urlImagenes: [],
        tags: _this.tags,
        visita:_this.visita
      };
      this.horizontalStepperStep1.disable();
      this.horizontalStepperStep2.disable();
      this.inputsDisabled = true;
      (document.getElementById('create-report-btn') as HTMLInputElement).disabled = true;
      this.fuseProgressBarService.show();
      this.uploadImages().pipe(takeUntil(_this._unsubscribeAll))
        .subscribe(() => {
          reportData.urlImagenes = _this.s3_urls;
          _this.civixApiService.crearReporteCRM2(reportData).pipe(takeUntil(_this._unsubscribeAll))
            .subscribe((data: any) => {
              if (data.ticketCic) {
                _this.fuseProgressBarService.hide();
                this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                  disableClose: true,
                  data: {
                    disableCancelar: true
                  }
                });
                if(!reportData.relevante){
                  this.confirmDialogRef.componentInstance.confirmTitle = 'Gracias por tu Reporte';
                  this.confirmDialogRef.componentInstance.confirmMessage = data.ticketCic;
                    this.confirmDialogRef.afterClosed().subscribe(result => {
                      console.log("crear");
                    this.router.navigate(['/crear']);
                    this.confirmDialogRef = null;
                  });
                } else {
                  this.confirmDialogRef.componentInstance.confirmTitle = 'Reporte Relevante Creado';
                  this.confirmDialogRef.componentInstance.confirmMessage = 'Folio: ' + data.ticketCic;
                  this.confirmDialogRef.componentInstance.confirmButtonText = 'Continuar';
                    this.confirmDialogRef.afterClosed().subscribe(result => {
                    this.confirmDialogRef = null;
                    let nombreCategoria = _this.categorias.find((categoria) => {
                      if(categoria._id === reportData.categoriaId){
                        return true;
                      }
                    });
                    if(nombreCategoria){
                      nombreCategoria = nombreCategoria.nombre;
                    }else{
                      nombreCategoria = '';
                    }
                    let correoRelevanteDialogRef = _this._matDialog.open(CorreoRelevanteDialogComponent, {
                      data: {
                        reporte: {
                          _id: data._id,
                          categoriaNombre: nombreCategoria || '',
                          descripcion: reportData.descripcion || '',
                          fechaEvento: reportData.fechaEvento || ''
                        }
                      },
                      disableClose: true
                    });
                      correoRelevanteDialogRef.afterClosed()
                        .pipe(takeUntil(_this._unsubscribeAll))
                        .subscribe((result: boolean) => {
                          _this.router.navigate(['/crm']);
                      });
                  });
                }
              } else {
                _this.fuseProgressBarService.hide();
                _this._snackBar.open('Error al crear reporte.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 5000
                });
                console.log('Error al crear reporte. Datos enviados:');
                console.log(reportData);
                console.log('response:');
                console.log(data);
                _this.reEnableButtons();
              }
            }, (error) => {
              _this.fuseProgressBarService.hide();
              _this._snackBar.open('Error al crear reporte.', 'Ok', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 5000
              });
              console.log(error);
              console.log('Error al crear reporte. Datos enviados:');
              console.log(reportData);
              _this.reEnableButtons();
            });
        }, (error) => {
          _this.fuseProgressBarService.hide();
          _this._snackBar.open('Error al subir imagenes.', 'Ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5000
          });
          console.log(error);
          console.log('Error uploading images');
          _this.reEnableButtons();
        });
    } else {
      return false;
    }
  }

  reEnableButtons(){
    this.horizontalStepperStep1.enable();
    this.horizontalStepperStep2.enable();
    this.inputsDisabled = false;
    (document.getElementById('create-report-btn') as HTMLInputElement).disabled = false;
  }

  resolved(captchaResponse: string): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    (document.getElementById('create-report-btn') as HTMLInputElement).disabled = false;
  }

  uploadImages(): Observable<void> {
    let _this = this;
    if (_this.images.length > 0) {
      return new Observable((observer) => {
        AWS.config.region = 'us-east-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'us-east-1:268b81c5-3923-4aea-9c14-f7ad5d61a701',
        });
        let bucketName = 'cicty/Reportes';
        let bucket = new AWS.S3({ params: { Bucket: bucketName } });
        let imagesRemaining = _this.images.length;
        for (let i = 0; i < _this.images.length; i++) {
          let file = _this.images[i].file;
          let array = file.name.split('.');
          function guid(ext) { return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + "." + ext; }
          function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }

          let llave = guid(array[array.length - 1]);
          file.nombre = llave;
          let nombre = llave;
          let params = { Key: llave, ContentType: file.type, Body: file, ServerSideEncryption: 'AES256' };

          bucket.putObject(params, function (err, data) {
            _this._ngZone.run(() => {
              if (err) {
                console.log(err);
                observer.error();
              } else {
                _this.s3_urls.push('https://s3.amazonaws.com/' + bucketName + '/' + nombre);
                imagesRemaining--;
              }
              if (imagesRemaining === 0) {
                observer.next();
                observer.complete();
              }
            });
          }
          );
        }
      });
    } else {
      return new Observable((observer) => {
        observer.next();
        observer.complete();
      });
    }
  }

  fileDragEnter() {
    this.droppingFile = true;
  }

  fileDragLeave() {
    this.droppingFile = false;
  }

  fileDrop($event, fileInputWrapper) {
    this.droppingFile = false;
    fileInputWrapper.launch($event.clientX, $event.clientY);
  }

  removeImage(imageToRemove) {
    let imageIndex = this.images.findIndex((image: any, i: number, array: any[]): boolean => {
      if (image.url === imageToRemove.url) {
        return true;
      } else {
        return false;
      }
    });
    this.images.splice(imageIndex, 1);
  }

  updateAddress($event) {
    this.horizontalStepperStep1.get('coordinates').setValue($event.coordinates);
    this.locationNotSelected = false;
    this.horizontalStepperStep1.get('formattedAddress').setValue($event.formattedAddress);
  }

  validateStep1() {
    if (this.horizontalStepperStep1.get('coordinates').invalid) {
      this.locationNotSelected = true;
    }
  }

  updateCategoryData(data){
    this.horizontalStepperStep2.get('categoriaId').setValue(data.categoriaId);
    this.tags = data.tags;
  }
}
