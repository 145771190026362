import { Component, OnInit, Input } from '@angular/core';
import { Report } from '../report-list/report.model';
import { CrmStateService } from '../crm-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FocusableOption } from '@angular/cdk/a11y';

@Component({
  selector: 'app-report-list-item',
  templateUrl: './report-list-item.component.html',
  styleUrls: ['./report-list-item.component.scss']
})
export class ReportListItemComponent implements OnInit, FocusableOption {

  @Input() report: Report;
  mediosFuente = {
    'twitter': "assets/icons/fuentes/twitter.png",
    'whatsapp': "assets/icons/fuentes/whatsapp.png",
    'telefono': "assets/icons/fuentes/telefono.png",
    'correo': "assets/icons/fuentes/email.png",
    'facebook': "assets/icons/fuentes/facebook.png",
    'cicEnTuColonia': "assets/icons/fuentes/cic_colonia.png",
    'waze': "assets/icons/fuentes/waze.png",
    'noticiero': "assets/icons/fuentes/noticias.png",
    'internet': "assets/icons/fuentes/internet.png",
    'app': "assets/icons/fuentes/civix.png"
  };

  sourceUrl: string;
  private _unsubscribeAll: Subject<any>;
  currentReport: Report;

  constructor(private _crmStateService: CrmStateService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    let _this = this;
    _this._crmStateService.currentReport
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe((report: Report) => {
        _this.currentReport = report;
      });
    let sourceUrl = window.location.protocol + '//' + window.location.host + '/';
    switch (this.report.medioReporte) {
      case 'App': {
        this.sourceUrl = sourceUrl + this.mediosFuente.app;
        break;
      }
      case 'Twitter': {
        this.sourceUrl = sourceUrl + this.mediosFuente.twitter;
        break;
      }
      case 'Whatsapp': {
        this.sourceUrl = sourceUrl + this.mediosFuente.whatsapp;
        break;
      }
      case 'Telefono': {
        this.sourceUrl = sourceUrl + this.mediosFuente.telefono;
        break;
      }
      case 'Correo Electrónico': {
        this.sourceUrl = sourceUrl + this.mediosFuente.correo;
        break;
      }
      case 'Facebook': {
        this.sourceUrl = sourceUrl + this.mediosFuente.facebook;
        break;
      }
      case 'CIC En Tu Colonia': {
        this.sourceUrl = sourceUrl + this.mediosFuente.cicEnTuColonia;
        break;
      }
      case 'Waze': {
        this.sourceUrl = sourceUrl + this.mediosFuente.waze;
        break;
      }
      case 'Noticiero': {
        this.sourceUrl = sourceUrl + this.mediosFuente.noticiero;
        break;
      }
      case 'Internet': {
        this.sourceUrl = sourceUrl + this.mediosFuente.internet;
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  focus(): void {
    if (this.currentReport && this.currentReport._id !== this.report._id) {
      this.report['_senderComponent'] = 'reportListItemComponent';
      this._crmStateService.setCurrentReport(this.report);
    } else if (!this.currentReport) {
      this.report['_senderComponent'] = 'reportListItemComponent';
      this._crmStateService.setCurrentReport(this.report);
    }
  }
}
