import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CivixApiService } from 'app/civix-api.service';
import { CrmCatalogsService } from 'app/crm/crm-catalogs.service';
import { AppStateService } from 'app/app-state.service';

@Component({
  selector: 'app-switch-observatorio',
  templateUrl: './switch-observatorio-dialog.component.html',
  styleUrls: ['./switch-observatorio-dialog.component.scss']
})
export class SwitchObservatorioDialogComponent implements OnInit {

  observatoriosDisponibles: any[];
  group = new FormControl(null, Validators.required);
  observatorio = new FormControl(null, Validators.required);
  groupFilter = new FormControl('');
  groups: any[];
  filteredGroups: any[];
  readyToSend: boolean = false;
  usuario: any;

  private _unsubscribeAll: Subject<any>;

  constructor(private crmCatalogsService: CrmCatalogsService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SwitchObservatorioDialogComponent>,
    private civixApiService: CivixApiService, private _snackBar: MatSnackBar, private appStateService: AppStateService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    let _this = this;
    this.appStateService.usuario
      .pipe(take(1))
      .subscribe((usuario: any) => {
        _this.usuario = usuario;
        _this.crmCatalogsService.observatoriosCic
          .pipe(takeUntil(_this._unsubscribeAll))
          .subscribe(observatorios => {
            _this.observatoriosDisponibles = observatorios;
            let observatorio = _this.observatoriosDisponibles.find((observatorio, i, array) => {
              if (observatorio._id === _this.usuario.observatorioId) {
                return true;
              }
            });
            if (observatorio) {
              _this.observatorio.setValue(observatorio);
              _this.getGruposObservatorio(observatorio);
            }
          });
      });
    this.groupFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterGroups();
      });
    this.group.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this.group.value && this.observatorio.value) {
          this.readyToSend = true;
        } else {
          this.readyToSend = false;
        }
      });
    this.observatorio.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((observatorio) => {
        _this.getGruposObservatorio(observatorio);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  cambiarObservatorio() {
    let _this = this;
    let data = {
      'token': sessionStorage.login,
      'usuarioId': _this.usuario.id,
      'correoElectronico': _this.usuario.correoElectronico,
      'nombre': _this.usuario.nombre,
      'perfilId': _this.usuario.perfilId,
      'twitter': _this.usuario.twitter,
      'grupoId': _this.group.value._id,
      'observatorioId': _this.observatorio.value._id
    };
    _this.civixApiService.editarUsuario(data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: any) => {
        if (response && response.status === 'ok') {
          let userData = _this.usuario;
          userData.grupoId = data.grupoId;
          userData.observatorioId = data.observatorioId;
          _this.appStateService.setUserData(userData);
          _this.dialogRef.close(true);
          _this._snackBar.open('Observatorio cambiado.', 'Ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000
          });
        } else {
          _this.dialogRef.close(false);
          _this._snackBar.open('Error al cambiar observatorio.', 'Ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000
          });
        }
      });
  }

  getGruposObservatorio(observatorio) {
    let _this = this;
    this.civixApiService.getGruposObservatorio(sessionStorage.login, observatorio._id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: any) => {
        this.groups = data.response;
        this.filteredGroups = this.groups;
        let group = this.groups.find((group, i, array) => {
          if (group._id === _this.usuario.grupoId) {
            return true;
          }
        });
        if (group) {
          _this.group.setValue(group);
        } else {
          _this.group.setValue(null);
        }
      });
  }

  private _filterGroups(): void {
    let search = this.groupFilter.value;
    if (!search) {
      this.filteredGroups = this.groups;
      return;
    } else {
      search = search.toLowerCase();
    }
    let filteredGroups = this.groups.filter(group => group.nombre.toLowerCase().includes(search));
    this.filteredGroups = filteredGroups;
  }
}
