import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Report } from './report-list/report.model';

@Injectable()
export class CrmStateService {

  filterMode: Observable<string>;
  filterSettings: Observable<any>;
  reportsPage: Observable<number>;
  currentReport: Observable<Report>;

  notifyBounceReportList: Subject<any>;

  private _filterMode: BehaviorSubject<string>;
  private _filterSettings: BehaviorSubject<any>;
  private _reportsPage: BehaviorSubject<number>;
  private _currentReport: BehaviorSubject<Report>;

  constructor() {
    this._filterMode = new BehaviorSubject('despacho');
    this._filterSettings = new BehaviorSubject({
      folioCic: '',
      palabraClave: '',
      tema: '',
      temaFiltro: '',
      categoria: '',
      categoriaFiltro: '',
      estatus: '',
      medio: '',
      fechaDesde: null,
      fechaHasta: null
    });
    this._reportsPage = new BehaviorSubject(0);
    this._currentReport = new BehaviorSubject(null);
    this.filterMode = this._filterMode.asObservable();
    this.filterSettings = this._filterSettings.asObservable();
    this.reportsPage = this._reportsPage.asObservable();
    this.currentReport = this._currentReport.asObservable();
    this.notifyBounceReportList = new Subject<any>();
  }

  getFilterMode() {
    return this._filterMode.value;
  }

  getFilterSettings() {
    return this._filterSettings.value;
  }

  setFilterSettings(filterSettings) {
    let newSettings = Object.assign({}, filterSettings);
    this._filterSettings.next(newSettings);
  }

  getReportsPage() {
    return this._reportsPage.value;
  }

  setFilterMode(mode: string) {
    this._filterMode.next(mode);
  }

  setReportsPage(page: number) {
    this._reportsPage.next(page);
  }

  setCurrentReport(report: Report) {
    if (report) {
      let newCurrentReport = Object.assign({}, report);
      this._currentReport.next(newCurrentReport);
    } else {
      this._currentReport.next(report);
    }
  }

  bounceReportList() {
    this.notifyBounceReportList.next();
  }
}
