import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CivixApiService } from 'app/civix-api.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
declare const AWS: any;

@Component({
  selector: 'app-add-comment-dialog',
  templateUrl: './add-comment-dialog.component.html',
  styleUrls: ['./add-comment-dialog.component.scss']
})
export class AddCommentDialogComponent implements OnInit {

  images: any[] = [];
  s3_urls: string[] = [];
  droppingFile: boolean = false;
  comment = new FormControl('');

  private _unsubscribeAll: Subject<any>;

  constructor(private _ngZone: NgZone, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddCommentDialogComponent>,
    private civixApiService: CivixApiService, private _snackBar: MatSnackBar, private _fuseProgressBarService: FuseProgressBarService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  uploadImages(): Observable<void> {
    let _this = this;
    if (_this.images.length > 0) {
      return new Observable((observer) => {
        AWS.config.region = 'us-east-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'us-east-1:268b81c5-3923-4aea-9c14-f7ad5d61a701',
        });
        let bucketName = 'cicty/comentarios';
        let bucket = new AWS.S3({ params: { Bucket: bucketName } });
        let imagesRemaining = _this.images.length;
        for (let i = 0; i < _this.images.length; i++) {
          let file = _this.images[i].file;
          let array = file.name.split('.');
          function guid(ext) { return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + "." + ext; }
          function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }

          let llave = guid(array[array.length - 1]);
          file.nombre = llave;
          let nombre = llave;
          let params = { Key: llave, ContentType: file.type, Body: file, ServerSideEncryption: 'AES256' };

          bucket.putObject(params, function (err, data) {
            _this._ngZone.run(() => {
              if (err) {
                console.log(err);
                observer.error();
              } else {
                _this.s3_urls.push('https://s3.amazonaws.com/' + bucketName + '/' + nombre);
                imagesRemaining--;
              }
              if (imagesRemaining === 0) {
                observer.next();
                observer.complete();
              }
            });
          }
          );
        }
      });
    } else {
      return new Observable((observer) => {
        observer.next();
        observer.complete();
      });
    }
  }

  fileDragEnter() {
    this.droppingFile = true;
  }

  fileDragLeave() {
    this.droppingFile = false;
  }

  fileDrop($event, fileInputWrapper) {
    this.droppingFile = false;
    fileInputWrapper.launch($event.clientX, $event.clientY);
  }

  removeImage(imageToRemove) {
    let imageIndex = this.images.findIndex((image: any, i: number, array: any[]): boolean => {
      if (image.url === imageToRemove.url) {
        return true;
      } else {
        return false;
      }
    });
    this.images.splice(imageIndex, 1);
  }

  fileChange($event) {
    let _this = this;
    let targetElement = ($event.target as HTMLInputElement);
    if (targetElement.files) {
      let fileCount = targetElement.files.length;
      for (let i = 0; i < targetElement.files.length; i++) {
        if (targetElement.files[i].type.includes('image/')) {
          let reader = new FileReader();
          reader.readAsDataURL(targetElement.files[i]);
          reader.onload = (event) => {
            let image = {
              url: reader.result,
              file: targetElement.files[i]
            };
            _this.images.push(image);
            fileCount--;
            if (fileCount === 0) {
              targetElement.value = '';
            }
          }
        }
      }
    }
  }

  addComment() {
    let _this = this;
    let parameters = {
      'token': sessionStorage.login,
      'geoPuntoId': this.data.geoPuntoId,
      'comentario': this.comment.value || '',
      'imagenes': []
    };
    _this._fuseProgressBarService.show();
    (document.getElementById('add-comment-btn') as HTMLInputElement).disabled = true;
    _this.uploadImages()
      .pipe(takeUntil(_this._unsubscribeAll))
      .subscribe(() => {
        parameters.imagenes = _this.s3_urls;
        if (_this.data.type === 'Nota Interna') {
          _this.civixApiService.crearNotaReporte(parameters)
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe((data: any) => {
              if (data.status && data.status.comentarios) {
                _this.dialogRef.close(data.status.comentarios);
                _this._snackBar.open('Nota creada.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 2000
                });
              } else {
                _this.dialogRef.close(false);
                _this._snackBar.open('Error al crear nota.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 5000
                });
              }
            });
        } else if (_this.data.type === 'Seguimiento') {
          _this.civixApiService.crearSeguimientoReporte(parameters)
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe((data: any) => {
              if (data.status && data.status.comentarios) {
                _this.dialogRef.close(data.status.comentarios);
                _this._snackBar.open('Seguimiento creado.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 2000
                });
              } else {
                _this.dialogRef.close(false);
                _this._snackBar.open('Error al crear seguimiento.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 5000
                });
              }
            });
        }
      }, (error) => {
        console.log('error uploading images');
      });
  }
}
