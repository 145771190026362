import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CivixApiService } from 'app/civix-api.service';
import { CrmCatalogsService } from 'app/crm/crm-catalogs.service';

@Component({
  selector: 'app-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss']
})
export class AddGroupDialogComponent implements OnInit {

  group = new FormControl(null, Validators.required);
  groupFilter = new FormControl('');
  groups: any[];
  filteredGroups: any[];

  readyToSend: boolean = false;
  private _unsubscribeAll: Subject<any>;

  constructor(private crmCatalogsService: CrmCatalogsService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddGroupDialogComponent>,
    private civixApiService: CivixApiService, private _snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.crmCatalogsService.grupos
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(grupos => {
        this.groups = grupos;
        this.filteredGroups = grupos;
      });
    this.groupFilter.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterGroups();
      });
    this.group.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this.group.value) {
          this.readyToSend = true;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addGroup() {
    let _this = this;
    let group = this.group.value;
    if (group) {
      let groupId = this.group.value ? this.group.value._id : null;
      var parametros = {
        'token': sessionStorage.login,
        'grupoId': groupId,
        'geoPuntos': [this.data.geoPuntoId]
      }
      this.readyToSend = false;
      if (groupId) {
        this.civixApiService.reasignarGrupo(parametros)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((response: any) => {
            if (response && response.response === 'ok') {
              _this.dialogRef.close(true);
              _this._snackBar.open('Grupo asignado.', 'Ok', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000
              });
            } else {
              _this.dialogRef.close(false);
              _this._snackBar.open('Error al asignar grupo.', 'Ok', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000
              });
            }
          });
      }
    }
  }

  private _filterGroups(): void {
    let search = this.groupFilter.value;
    if (!search) {
      this.filteredGroups = this.groups;
      return;
    } else {
      search = search.toLowerCase();
    }
    let filteredGroups = this.groups.filter(group => group.nombre.toLowerCase().includes(search));
    this.filteredGroups = filteredGroups;
  }
}
