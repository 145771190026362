import {
    Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewChild,
    ChangeDetectorRef, ViewChildren, QueryList
} from '@angular/core';
import { Subject } from 'rxjs';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { fuseAnimations } from '@fuse/animations';
import { Report } from 'app/crm/report-list/report.model';
import { CivixApiService } from 'app/civix-api.service';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { CrmStateService } from '../crm-state.service';
import { ReportListItemComponent } from '../report-list-item/report-list-item.component';
import { OverlayContainer } from '@angular/cdk/overlay';


@Component({
    selector: 'report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.scss'],
    animations: fuseAnimations
})
export class ReportListComponent implements OnInit, OnDestroy {
    @Output() scrollEndBottom = new EventEmitter<void>();
    @Input() reports: Report[];
    currentReport: Report;
    reportDetail: any;

    private changedReportList: boolean = false;
    private _unsubscribeAll: Subject<any>;
    @ViewChild('reportListScrollbar', { static: false })
    private _reportListScrollbar: FusePerfectScrollbarDirective;
    @ViewChildren(ReportListItemComponent) reportComponents: QueryList<ReportListItemComponent>;
    private _keyManager: FocusKeyManager<ReportListItemComponent>;

    constructor(private _civixApiService: CivixApiService, private _crmStateService: CrmStateService, private cd: ChangeDetectorRef,
        private _cdkOverlayContainer: OverlayContainer) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        let _this = this;
        _this._crmStateService.setCurrentReport(null);
        _this._crmStateService.currentReport
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe((report: Report) => {
                _this.currentReport = report;
                if (report) {
                    if (report['_senderComponent'] === 'crmComponent') {
                        let reportIndex = _this.getCurrentReportIndex();
                        setTimeout(() => {
                            //wait until next cycle for 'currentReport' in reportListItemComponent to already be updated
                            _this._keyManager.setActiveItem(reportIndex);
                            _this.focusSelectedReport();
                        }, 0);
                        _this.getReportDetail(report);
                        _this.scrollToCurrentReport();
                    }
                    if (report['_senderComponent'] === 'reportListComponent') {
                        let reportIndex = _this.getCurrentReportIndex();
                        setTimeout(() => { _this._keyManager.setActiveItem(reportIndex); }, 0);
                        _this.getReportDetail(report);
                    }
                    if (report['_senderComponent'] === 'detailReport_relocate') {
                        _this.updateReportCoordinates(report);
                    }
                }
            });
        //debounce keyboard navigation observer
        _this._crmStateService.currentReport
            .pipe(debounceTime(500), takeUntil(_this._unsubscribeAll))
            .subscribe((report: Report) => {
                _this.currentReport = report;
                if (report) {
                    if (report['_senderComponent'] === 'reportListItemComponent') {
                        _this._civixApiService.getReporteCRM(sessionStorage.login, report._id)
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe((data: any) => {
                                _this.markAsRead(report._id);
                                this.reportDetail = data;
                                console.log(data);
                            });
                    }
                }
            });
        _this._crmStateService.notifyBounceReportList
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe(() => {
                if (_this._reportListScrollbar) {
                    _this._reportListScrollbar.scrollToY(_this._reportListScrollbar.elementRef.nativeElement.scrollTop - 1);
                }
            });
    }

    ngAfterViewInit() {
        let _this = this;
        this._keyManager = new FocusKeyManager(this.reportComponents);
        this.reportComponents.changes
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((reportList: QueryList<ReportListItemComponent>) => {
                this._keyManager = new FocusKeyManager(reportList);
                setTimeout(() => {
                    let reportIndex = _this.getCurrentReportIndex();
                    if (reportIndex > -1) {
                        _this._keyManager.setActiveItem(reportIndex);
                    }
                }, 0);
            });
    }

    ngOnChanges() {
        this.changedReportList = true;
        if (this._reportListScrollbar) {
            if (this._crmStateService.getFilterMode() === 'reportes-cercanos' || this._crmStateService.getReportsPage() === 0) {
                this._reportListScrollbar.scrollToTop();
            }
        }
    }

    ngAfterViewChecked() {
        //#labelText template reference variable of DOM element changes after rendered. need to know if showing all text or ellipsis
        if (this.changedReportList) {
            this.cd.detectChanges();
            this.changedReportList = false;
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    selectReport(report: Report) {
        report['_senderComponent'] = 'reportListComponent';
        this._crmStateService.setCurrentReport(report);
    }

    emitScrollEndBottom() {
        if (this._reportListScrollbar) {
            let nativeElement = this._reportListScrollbar.elementRef.nativeElement;
            if (nativeElement.scrollHeight > nativeElement.offsetHeight) {
                this.scrollEndBottom.emit();
            }
        }
    }

    scrollToCurrentReport() {
        let _this = this;
        let reportIndex = _this.reports.findIndex((report, i, array) => {
            if (report.folioCic === _this.currentReport.folioCic) {
                return true;
            }
        });
        let scrollPositionY = 105 * reportIndex;
        if (_this._reportListScrollbar) {
            _this._reportListScrollbar.scrollToY(scrollPositionY);
        }
    }

    getReportDetail(report) {
        let _this = this;
        _this._civixApiService.getReporteCRM(sessionStorage.login, report._id)
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe((data: any) => {
                _this.reportDetail = data;
                _this.markAsRead(report._id);
                console.log(data);
            });
    }

    getCurrentReportIndex(): number {
        let _this = this;
        if (_this.reports && _this.currentReport) {
            let reportIndex = _this.reports.findIndex((report, i, array) => {
                if (report.folioCic === _this.currentReport.folioCic) {
                    return true;
                }
            });
            return reportIndex;
        } else {
            return -1;
        }
    }

    focusSelectedReport($event?) {
        if (this._cdkOverlayContainer.getContainerElement().getElementsByClassName('cdk-overlay-backdrop').length > 0) {
            return;
        }
        if ($event) {
            $event.stopImmediatePropagation();
        }
        let elementCollection = document.getElementsByClassName('report-list-item-container selected');
        if (elementCollection.length > 0) {
            (elementCollection[0].parentNode as HTMLElement).focus();
            if ($event) {
                this._keyManager.onKeydown($event);
            }
        } else {
            let elementCollection = document.getElementsByClassName('report-list-item-container');
            if (elementCollection.length > 0) {
                this._keyManager.setActiveItem(0);
            }
        }
    }

    updateReportCoordinates(report: Report) {
        let _this = this;
        let updatedReport = _this.reports.find((r, i, reports) => {
            if (r.folioCic === report.folioCic) {
                return true;
            }
        });
        updatedReport.geo = report.geo;
    }

    markAsRead(reportId) {
        let _this = this;
        let report = _this.reports.find((r, i, reports) => {
            if (r._id === reportId) {
                return true;
            }
        });
        if(report){
            report.leido = true;
        }
    }
}
