import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CivixApiService } from 'app/civix-api.service';

@Component({
  selector: 'app-relocate-report-dialog',
  templateUrl: './relocate-report-dialog.component.html',
  styleUrls: ['./relocate-report-dialog.component.scss']
})
export class RelocateReportDialogComponent implements OnInit {

  readyToSend: boolean = false;
  addressFields: FormGroup;
  private _unsubscribeAll: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RelocateReportDialogComponent>,
    private civixApiService: CivixApiService, private _snackBar: MatSnackBar, private _formBuilder: FormBuilder) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.addressFields = this._formBuilder.group({
      coordinates: [null, Validators.required],
      formattedAddress: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateAddressFields($event) {
    this.addressFields.get('coordinates').setValue($event.coordinates);
    this.addressFields.get('formattedAddress').setValue($event.formattedAddress);
    if (this.addressFields.get('coordinates').value && this.addressFields.get('formattedAddress').value) {
      this.readyToSend = true;
    } else {
      this.readyToSend = false;
    }
  }

  updateReportAddress() {
    let _this = this;
    let coordinates = this.addressFields.get('coordinates').value;
    let formattedAddress = this.addressFields.get('formattedAddress').value;
    if (coordinates && formattedAddress) {
      var parametros = {
        'token': sessionStorage.login,
        'latitud': coordinates.latitud,
        'longitud': coordinates.longitud,
        'direccionTextual': formattedAddress,
        'geoPuntoId': this.data.geoPuntoId
      }
      this.readyToSend = false;
      this.civixApiService.actualizarUbicacion(parametros)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
          if (response && response.response === 'ok') {
            _this.dialogRef.close({
              coordinates: coordinates,
              formattedAddress: formattedAddress
            });
            _this._snackBar.open('Ubicación actualizada.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          } else {
            _this.dialogRef.close(false);
            _this._snackBar.open('Error al actualizar ubicación.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          }
        });
    }
  }
}
