import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { CivixApiService } from '../civix-api.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _userData: BehaviorSubject<any>;

  isLoggedIn = false;
  redirectUrl: string;

  constructor(private ngZone: NgZone, private router: Router, private civixApiService: CivixApiService, 
    private _fuseConfigService: FuseConfigService) {
      this._userData = new BehaviorSubject({});
  }

  login(loginData): void {
    let _this = this;
    _this.ngZone.run(() => {
      this.civixApiService.login(loginData).subscribe((data: any) => {
        if (data.response.token) {
          _this.isLoggedIn = true;
          sessionStorage.setItem('login', data.response.token);
          sessionStorage.setItem('nombre', loginData.nombre);
          sessionStorage.setItem('imagen', loginData.imagen);
          localStorage.setItem('login', data.response.token);
          localStorage.setItem('nombre', loginData.nombre);
          localStorage.setItem('imagen', loginData.imagen);
          _this._userData.next({
            nombre: loginData.nombre,
            imagen: loginData.imagen
          });
          console.log('navigating to ' + _this.redirectUrl);
          if(!_this.redirectUrl){
            _this.redirectUrl = '/crm';
          }
          _this.router.navigate([_this.redirectUrl]);
          console.log('logged in');
          _this._fuseConfigService.config = {
            layout: {
              navbar: {
                hidden: false
              },
              toolbar: {
                hidden: false
              }
            }
          };
        } else {
          _this.isLoggedIn = false;
          sessionStorage.clear();
          localStorage.removeItem('login');
          localStorage.removeItem('imagen');
          localStorage.removeItem('nombre');
        }
      }, (error => {
        console.log(error);
      }));
    });
  }

  getUserData(): Observable<any>{
    return this._userData.asObservable();
  }

  userVerified(){
    this.isLoggedIn = true;
    this._userData.next({
      nombre: sessionStorage.getItem('nombre'),
      imagen: sessionStorage.getItem('imagen')
    });
  }
}