import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    'id': 'modulos',
    'title': '',
    'type': 'group',
    'children': [
      {
        'id': 'xmxmxm',
        'title': 'CRM',
        'type': 'item',
        'icon': 'chrome_reader_mode',
        'url': '/crm'
      },
      {
        'id': 'xmzzz',
        'title': 'Reportes',
        'type': 'item',
        'icon': 'cloud_download',
        'url': '/reportes'
      }
    ]
  }];
