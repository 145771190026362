import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CivixApiService } from 'app/civix-api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CrmCatalogsService } from 'app/crm/crm-catalogs.service';

@Component({
  selector: 'app-close-report-dialog',
  templateUrl: './close-report-dialog.component.html',
  styleUrls: ['./close-report-dialog.component.scss']
})
export class CloseReportDialogComponent implements OnInit {

  titulo = 'Cerrar Reporte';
  tituloBoton = 'Cerrar Reporte';
  comment = new FormControl('');
  tipoDonacion = new FormControl(null, Validators.required);
  reason = new FormControl(null, Validators.required);
  reasons: any[];
  readyToClose: boolean = false;
  private _unsubscribeAll: Subject<any>;

  constructor(private crmCatalogsService: CrmCatalogsService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CloseReportDialogComponent>,
    private civixApiService: CivixApiService, private _snackBar: MatSnackBar) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.crmCatalogsService.motivosCierre
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(motivos => {
        this.reasons = motivos;
      });
    this.reason.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this.reason.value) {
          this.readyToClose = true;
        }
      });
    this.tipoDonacion.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this.tipoDonacion.value) {
          this.readyToClose = true;
        }
      });
    if (this.data.seleccionarTipoDonacion) {
      this.titulo = 'Cambiar estatus a En Proceso';
      this.tituloBoton = 'Cambiar';
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  closeReport() {
    let _this = this;
    let reason = this.reason.value;
    if (this.data.seleccionarTipoDonacion && this.tipoDonacion.value) {
      this.readyToClose = false;
      this.civixApiService.cambiarEstatusReporte({
        "token": sessionStorage.login,
        "geoPuntoId": this.data.geoPuntoId,
        "estatus": 'en proceso',
        "tipoDonacion": this.tipoDonacion.value
      }).pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
          if (response && response.response === 'ok') {
            _this.dialogRef.close(true);
            _this._snackBar.open('Estatus actualizado.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          } else {
            _this.dialogRef.close(false);
            _this._snackBar.open('Error al actualizar el estatus.', 'Ok', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000
            });
          }
        });
    }
    else {
      if (reason) {
        let motivoCierreId = this.reason.value ? this.reason.value._id : null;
        var parametros = {
          'token': sessionStorage.login,
          'geoPuntoId': this.data.geoPuntoId,
          'comentario': this.comment.value,
          'motivoCierreId': motivoCierreId
        }
        if (motivoCierreId) {
          this.readyToClose = false;
          this.civixApiService.cerrarReporte(parametros)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
              if (response && response.response === 'ok') {
                _this.dialogRef.close(true);
                _this._snackBar.open('Reporte cerrado.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 2000
                });
              } else {
                _this.dialogRef.close(false);
                _this._snackBar.open('Error al cerrar reporte.', 'Ok', {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 2000
                });
              }
            });
        }
      }
    }
  }
}
