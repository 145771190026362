import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CrmCatalogsService } from '../crm-catalogs.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CivixApiService } from 'app/civix-api.service';

@Component({
  selector: 'app-categorize-report',
  templateUrl: './categorize-report.component.html',
  styleUrls: ['./categorize-report.component.scss']
})
export class CategorizeReportComponent implements OnInit {

  @Output() categoryDataChange = new EventEmitter<any>();

  @Input() currentCategoryId: any;
  @Input() currentTags: any[] = [];
  @Input() inputsDisabled: boolean;
  @Input() location: any;

  temas: any[];
  temasFiltrados: any[];
  categorias: any[];
  categoriasFiltradas: any[];
  tipos: any[];
  tiposFiltrados: any[];
  atributosCheckbox: any[] = [];
  atributosCheckboxSeleccionados: string[] = [];
  atributosRadioButton: any[] = [];
  categorizationFormFields: FormGroup;

  private _unsubscribeAll: Subject<any>;
  private renderedCurrentData = false;

  constructor(private _formBuilder: FormBuilder, private crmCatalogsService: CrmCatalogsService, private civixApiService: CivixApiService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    let _this = this;
    this.categorizationFormFields = this._formBuilder.group({
      tema: [{}, [Validators.required]],
      temaFiltro: null,
      categoria: [null, Validators.required],
      categoriaFiltro: null,
      tipo: [{}],
      tipoFiltro: null,
      atributosCheckboxArray: this._formBuilder.array([]),
      atributosRadioButton: this._formBuilder.array([])
    });

    this.categorizationFormFields.get('tema').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterCategorias();
        let categoria = this.categoriasFiltradas.length > 0 ? this.categoriasFiltradas[0] : null;
        this.categorizationFormFields.get('categoria').setValue(categoria);
      });
    this.categorizationFormFields.get('tipo').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._extractAtributos();
        this.emitDataChange();
      });
    this.categorizationFormFields.get('categoria').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._extractTipos();
      });
    this.categorizationFormFields.get('temaFiltro').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterTemas();
      });

    this.categorizationFormFields.get('categoriaFiltro').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterCategorias();
      });

    this.categorizationFormFields.get('tipoFiltro').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._filterTipos();
      });
      //es creado con location solo al editar categoría en reporte existente
      if(this.location.longitud){
        this.civixApiService.getTemasParaUbicacion(sessionStorage.login, 
        { 
          longitud: this.location.longitud,
          latitud: this.location.latitud
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((dataTemas: any) => {
          let temas = [];
          if(dataTemas.response && dataTemas.response.length > 0){
            temas = dataTemas.response;
          }
  
          this.civixApiService.getCategoriasParaUbicacion(sessionStorage.login, 
            { 
              longitud: this.location.longitud,
              latitud: this.location.latitud
            })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: any) => {
              if(data.response && data.response.length > 0){
                data.response = data.response.filter((categoria) => {
                  if(categoria.publicado === false){
                    return false;
                  }else{
                    return true;
                  }
                });
              }
              this.temas = temas;
              this.temasFiltrados = temas;
              let categorias = data.response;
              this.categorias = categorias;
              this.categoriasFiltradas = categorias;
              if (this.currentCategoryId) {
                let categoria = this.categorias.find((categoria, i, categorias) => {
                  if (categoria._id === _this.currentCategoryId) {
                    return true;
                  }
                });
                if (categoria) {
                  let tema = this.temas.find((tema, i, temas) => {
                    if (tema.id === categoria.temaId) {
                      return true;
                    }
                  });
                  if (tema) {
                    _this.categorizationFormFields.get('tema').setValue(tema, {
                      emitEvent: false
                    });
                    _this._filterCategorias();
                  }
                  _this.categorizationFormFields.get('categoria').setValue(categoria);
                }
              }
            });
        });
      }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    let _this = this;
    if (this.inputsDisabled) {
      this.categorizationFormFields.disable({emitEvent: false});
    }else if(this.inputsDisabled === false){
      if(this.categorizationFormFields){
        this.categorizationFormFields.enable({emitEvent: false});
      }
    }
    //en CreateReport se crea este componente desde el inicio, sin location. cuando se selecciona la dirección entra aquí
    if(this.location.longitud && changes['location'] && changes['location'].currentValue !== changes['location'].previousValue){
      this.civixApiService.getTemasParaUbicacion(sessionStorage.login, 
        { 
          longitud: this.location.longitud,
          latitud: this.location.latitud
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((dataTemas: any) => {
        let temas = [];
        if(dataTemas.response && dataTemas.response.length > 0){
          temas = dataTemas.response;
        }

        this.civixApiService.getCategoriasParaUbicacion(sessionStorage.login, 
          { 
            longitud: this.location.longitud,
            latitud: this.location.latitud
          })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((data: any) => {
            if(data.response && data.response.length > 0){
              data.response = data.response.filter((categoria) => {
                if(categoria.publicado === false){
                  return false;
                }else{
                  return true;
                }
              });
            }
            this.temas = temas;
            this.temasFiltrados = temas;
            let categorias = data.response;
            this.categorias = categorias;
            this.categoriasFiltradas = categorias;
            if (this.currentCategoryId) {
              let categoria = this.categorias.find((categoria, i, categorias) => {
                if (categoria._id === _this.currentCategoryId) {
                  return true;
                }
              });
              if (categoria) {
                let tema = this.temas.find((tema, i, temas) => {
                  if (tema.id === categoria.temaId) {
                    return true;
                  }
                });
                if (tema) {
                  _this.categorizationFormFields.get('tema').setValue(tema, {
                    emitEvent: false
                  });
                  _this._filterCategorias();
                }
                _this.categorizationFormFields.get('categoria').setValue(categoria);
              }
            }
          });
      });
    }
  }

  get atributosCheckboxFormArray() {
    return this.categorizationFormFields.get('atributosCheckboxArray') as FormArray;
  }

  get atributosRadioButtonForm() {
    return this.categorizationFormFields.get('atributosRadioButton') as FormArray;
  }

  attributeCheckboxChanged($event, respuesta) {
    if (respuesta.atributoId) {
      if ($event.checked) {
        this.atributosCheckboxSeleccionados.push(respuesta.atributoId);
      } else {
        let index = this.atributosCheckboxSeleccionados.findIndex((atributoId, index, array) => {
          if (atributoId === respuesta.atributoId) {
            return true;
          } else {
            return false;
          }
        });
        this.atributosCheckboxSeleccionados.splice(index, 1);
      }
      this.emitDataChange();
    }
  }

  attributeRadioButtonChanged() {
    this.emitDataChange();
  }

  private _filterTemas(): void {
    let search = this.categorizationFormFields.get('temaFiltro').value;
    if (!search) {
      this.temasFiltrados = this.temas;
      return;
    } else {
      search = search.toLowerCase();
    }
    let temasFiltrados = this.temas.filter(tema => tema.nombre.toLowerCase().includes(search));
    this.temasFiltrados = temasFiltrados;
  }

  private _filterCategorias(): void {
    let tema = this.categorizationFormFields.get('tema').value;
    let searchCategoria = this.categorizationFormFields.get('categoriaFiltro').value || '';
    if (tema) {
      let categoriasFiltradas = this.categorias.filter(categoria => {
        if (categoria.temaId === tema.id) {
          return categoria.nombre.toLowerCase().includes(searchCategoria.toLowerCase());
        } else {
          return false;
        }
      });
      this.categoriasFiltradas = categoriasFiltradas.sort(this.categorySortFunction);
    } else {
      let categoriasFiltradas = this.categorias.filter(categoria => {
        return categoria.nombre.toLowerCase().includes(searchCategoria.toLowerCase());
      });
      this.categoriasFiltradas = categoriasFiltradas.sort(this.categorySortFunction);
    }
  }

  private categorySortFunction(a, b) {
    let catA = a.nombre.toLowerCase();
    let catB = b.nombre.toLowerCase();
    if (catA > catB) {
      return 1;
    } else if (catA < catB) {
      return -1;
    } else {
      return 0;
    }
  }

  private _filterTipos(): void {
    let search = this.categorizationFormFields.get('tipoFiltro').value;
    if (!search) {
      this.tiposFiltrados = this.tipos;
      return;
    } else {
      search = search.toLowerCase();
    }
    let tiposFiltrados = this.tipos.filter(tipo => tipo.nombre.toLowerCase().includes(search));
    this.tiposFiltrados = tiposFiltrados;
  }

  private _extractTipos() {
    let categoria = this.categorizationFormFields.get('categoria').value;
    if (categoria && categoria.arbolDecision && categoria.arbolDecision.siguientePregunta
      && categoria.arbolDecision.siguientePregunta.respuestas) {
      let respuestas = categoria.arbolDecision.siguientePregunta.respuestas;
      this.tipos = respuestas;
      this.tiposFiltrados = respuestas;
      if (this.currentTags.length > 0 && !this.renderedCurrentData) {
        let tipoFound;
        for (let i = 0; i < this.currentTags.length; i++) {
          let tipoResult = this.tipos.find((tipo, index, tipos) => {
            if (tipo.atributoId === this.currentTags[i]) {
              return true;
            }
          });
          if(tipoResult){
            tipoFound = tipoResult;
          }
        }
        if (tipoFound) {
          this.categorizationFormFields.get('tipo').setValue(tipoFound);
        }
        if (!tipoFound) {
          let tipo = this.tipos.length > 0 ? this.tipos[0] : {};
          this.categorizationFormFields.get('tipo').setValue(tipo);
        }
      } else {
        let tipo = this.tipos.length > 0 ? this.tipos[0] : {};
        this.categorizationFormFields.get('tipo').setValue(tipo);
      }
    } else {
      this.tipos = [];
      this.tiposFiltrados = [];
      this.renderedCurrentData = true;
    }
    this.emitDataChange();
  }

  private _extractAtributos() {
    let tipo = this.categorizationFormFields.get('tipo').value;
    this.atributosCheckbox = [];
    this.atributosCheckboxSeleccionados = [];
    this.atributosRadioButton = [];
    this.atributosCheckboxFormArray.clear();
    this.atributosRadioButtonForm.clear();
    if (tipo && tipo.siguientePregunta && tipo.siguientePregunta.respuestas) {
      let atributos = tipo.siguientePregunta.respuestas;
      for (let i = 0; i < atributos.length; i++) {
        if (atributos[i].tipoRespuesta === 'OpcionMultiple') {
          if (atributos[i].siguientePregunta && atributos[i].siguientePregunta.respuestas) {
            this.atributosCheckboxFormArray.push(this._formBuilder.array([]));
            this.atributosCheckbox.push(atributos[i]);
            for (let j = 0; j < atributos[i].siguientePregunta.respuestas.length; j++) {
              let tagFound;
              if (this.currentTags && !this.renderedCurrentData) {
                tagFound = this.currentTags.find((tag, index, tags) => {
                  if (atributos[i].siguientePregunta.respuestas[j].atributoId === tag) {
                    return true;
                  }
                });
              }
              let checkboxFormControl;
              if (!tagFound) {
                checkboxFormControl = this._formBuilder.control(null);
              } else {
                checkboxFormControl = this._formBuilder.control(atributos[i].siguientePregunta.respuestas[j]);
                this.atributosCheckboxSeleccionados.push(atributos[i].siguientePregunta.respuestas[j].atributoId);
              }
              (this.atributosCheckboxFormArray.at(this.atributosCheckboxFormArray.length - 1) as FormArray).push(checkboxFormControl);
            }
          }
        } else if (atributos[i].tipoRespuesta === 'UnicaOpcion') {
          let tagFound;
          if (this.currentTags && !this.renderedCurrentData) {
            for (let j = 0; j < atributos[i].siguientePregunta.respuestas.length; j++) {
              let tagResult = this.currentTags.find((tag, index, tags) => {
                if (atributos[i].siguientePregunta.respuestas[j].atributoId === tag) {
                  return true;
                }
              });
              if (tagResult) {
                tagFound = atributos[i].siguientePregunta.respuestas[j];
              }
            }
          }
          if (!tagFound) {
            this.atributosRadioButtonForm.push(this._formBuilder.control(null));
          } else {
            this.atributosRadioButtonForm.push(this._formBuilder.control(tagFound));
          }
          this.atributosRadioButton.push(atributos[i]);
        }
      }
    }
    this.renderedCurrentData = true;
    this.emitDataChange();
  }

  private getTags() {
    let tags = [];
    let tipo = this.categorizationFormFields.get('tipo').value;
    if (tipo.atributoId) {
      tags.push(tipo.atributoId);
    }

    for (let i = 0; i < this.atributosCheckboxSeleccionados.length; i++) {
      tags.push(this.atributosCheckboxSeleccionados[i]);
    }

    for (let i = 0; i < this.atributosRadioButtonForm.length; i++) {
      if (this.atributosRadioButtonForm.at(i).value && this.atributosRadioButtonForm.at(i).value.atributoId) {
        tags.push(this.atributosRadioButtonForm.at(i).value.atributoId);
      }
    }
    return tags;
  }

  private emitDataChange() {
    let category = this.categorizationFormFields.get('categoria').value;
    if (category && category._id) {
      this.categoryDataChange.emit({
        categoriaId: category._id,
        tags: this.getTags()
      });
    } else {
      this.categoryDataChange.emit({
        categoriaId: null,
        tags: this.getTags()
      });
    }
  }
}
