import { Component, OnDestroy, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { AuthService } from '../../../auth/auth.service';
import { navigation } from 'app/navigation/navigation';
import { MatDialog } from '@angular/material';
import { SwitchObservatorioDialogComponent } from 'app/crm/crm-dialogs/switch-observatorio-dialog/switch-observatorio-dialog.component';
import { AppStateService } from 'app/app-state.service';
import { CrmCatalogsService } from 'app/crm/crm-catalogs.service';
import { environment } from '../../../../environments/environment';

declare const gapi: any;

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    nombre: string;
    imagen: string;
    darkThemeSelected: boolean;
    userData: any;
    allowSwitch: boolean;
    isTesting: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _ngZone: NgZone,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private authService: AuthService,
        private _router: Router,
        private dialog: MatDialog,
        private appStateService: AppStateService,
        private crmCatalogsService: CrmCatalogsService
    ) {
        this.navigation = navigation;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        let _this = this;
        // Subscribe to the config changes
        if(environment.apiUrl.indexOf('testing') > -1){
            _this.isTesting = true;
        }
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
                this.darkThemeSelected = settings.colorTheme === 'theme-blue-gray-dark';
            });
        this.authService.getUserData().pipe(takeUntil(this._unsubscribeAll))
            .subscribe((userData) => {
                this.nombre = userData.nombre;
                this.imagen = userData.imagen;
            });
        this.appStateService.usuario
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((usuario) => {
                this.userData = usuario;
                if (usuario) {
                    this.crmCatalogsService.observatoriosCic
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((observatorios) => {
                            let observatorio = observatorios.find((observatorio, i, array) => {
                                if (observatorio._id === _this.userData.observatorioId) {
                                    return true;
                                }
                            });
                            if(observatorio){
                                _this.allowSwitch = true;
                            }else {
                                _this.allowSwitch = false;
                            }
                        });
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    logout() {
        let _this = this;
        gapi.load('auth2', () => {
            gapi.auth2.init({
                client_id: '198208017026-3gifht6hcefrk13tsian714v20ts2e7b.apps.googleusercontent.com',
            }).then(() => {
                let auth2 = gapi.auth2.getAuthInstance();
                auth2.signOut().then(function () {
                    _this._ngZone.run(() => {
                        _this._router.navigate(['/login']);
                        sessionStorage.clear();
                        localStorage.removeItem('login');
                        localStorage.removeItem('imagen');
                        localStorage.removeItem('nombre');
                        console.log('User signed out.');
                    });
                });
            });
        });
    }

    temaToggleChange($event) {
        if ($event.checked) {
            this._fuseConfigService.setConfig({ colorTheme: 'theme-blue-gray-dark' });
            localStorage.setItem('color-theme', 'theme-blue-gray-dark');
        } else {
            this._fuseConfigService.setConfig({ colorTheme: 'theme-default' });
            localStorage.setItem('color-theme', 'theme-default');
        }
    }

    openSwitchObservatorioDialog() {
        this.dialog.open(SwitchObservatorioDialogComponent);
    }
}
